import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import DefaultAuth from "../../../layouts/non-auth/Default";
import illustration from "assets/img/auth/auth.jpg";
import { useDispatch } from "react-redux";
import { useRouter } from "../../../common/useRouter";
import { useForm, Controller } from "react-hook-form";
import CustomInput from "../../../components/custom-input";
import { performLogin } from "../../../application/action/auth_action";
import { WebUrl } from "../../../routes";
import ApiEngine from "../../../common/api-engine";
import Routes from "../../../common/api_routes";
import { ApiKey } from "../../../common/constant";
import { ConfigEnum } from "../../../common/config";
import { AuthActionTypes } from "../../../application/reducer/auth_reducer";
import { showCustomDialog } from "../../../application/action/app_action";
import { stringIsNullOrEmpty } from "../../../common/util";

/// <summary>
/// Author: Saitama
/// </summary>
function SignIn() {
  var _router = useRouter();
  let { search } = useLocation();
  const _query = new URLSearchParams(search);
  const { t } = useTranslation();
  const _dispatch = useDispatch();
  const { control, handleSubmit, errors, setValue, watch } = useForm();
  const textColor = useColorModeValue("navy.700", "white");
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.700", "white");

  /// <summary>
  /// Author: Saitama
  /// </summary>
  useEffect(() => {
    (async () => {
      try {
        if (!stringIsNullOrEmpty(_query?.get('token'))) {
          let params = {
            token: _query?.get('token'),
            isAdminPortal: true
          };
          var responseJson = await ApiEngine.post(Routes._ACCOUNT_VERIFY, params);

          if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
            throw responseJson[ApiKey._API_MESSAGE_KEY];
          }

          await localStorage.setItem(ConfigEnum._USER_DATA, JSON.stringify(responseJson[ApiKey._API_DATA_KEY]));
          await localStorage.setItem(ConfigEnum._BEARER_TOKEN, responseJson[ApiKey._API_DATA_KEY]['token']);

          _dispatch({
            type: AuthActionTypes._LOGIN_SUCCESS,
            userData: responseJson[ApiKey._API_DATA_KEY]
          });
        }
      }
      catch (err) {
        _dispatch(showCustomDialog({ success: false, content: err }));
      }
    })();
  }, [])

  /// <summary>
  /// Author: Saitama
  /// </summary>
  const submitForm = async (data, e) => {
    try {
      _dispatch(performLogin(data.username, data.password));
    }
    catch (err) {

    }
    finally {

    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        alignSelf="self-end"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            {t("SIGN_IN")}
          </Heading>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl>
            <form onSubmit={handleSubmit(submitForm)}>
              <Controller
                control={control}
                name="username"
                defaultValue={""}
                render={({ onChange, value, name }) => (
                  <CustomInput
                    id={name}
                    onChange={(value) => onChange(value)}
                    value={value}
                    label={"EMAIL"}
                    placeHolder={"ENTER_EMAIL"}
                    errors={errors}
                  />
                )}
                rules={{
                  required: true,
                  message: "REQUIRED",
                }}
              />
              <Controller
                control={control}
                name="password"
                defaultValue={""}
                render={({ onChange, value, name }) => (
                  <CustomInput
                    id={name}
                    onChange={(value) => onChange(value)}
                    value={value}
                    isPassword
                    label={"ENTER_PASSWORD"}
                    placeHolder={"PASSWORD"}
                    errors={errors}
                  />
                )}
                rules={{
                  required: true,
                  message: "REQUIRED",
                }}
              />
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
              >
                {t('SIGN_IN')}
              </Button>
            </form>
            <Flex justifyContent="space-between" align="center" mb="24px">
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  id="remember-login"
                  colorScheme="brandScheme"
                  me="10px"
                />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color={textColor}
                  fontSize="sm"
                >
                  {t('KEEP_ME_LOGGED_IN')}
                </FormLabel>
              </FormControl>
              <NavLink to={WebUrl._FORGOT_PASSWORD}>
                <Text
                  color={textColorBrand}
                  fontSize="sm"
                  w="124px"
                  fontWeight="500"
                >
                  {t('FORGOT_PASSWORD_?')}
                </Text>
              </NavLink>
            </Flex>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              {t('NOT_REGISTERED_YET_?')}
              <NavLink to={WebUrl._REGISTER}>
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  {t('CREATE_AN_ACCOUNT')}
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
