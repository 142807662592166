export const _WEB_VERSION = '1.0.0';
export const _VERSION_BUILD_NUMBER = '2';

/// <summary>
/// Author: Saitama
/// </summary>
export class SessionKey {
  static _ROUTE_TO_PROCEED = "routeToProceed";
}

/// <summary>
/// Author: Saitama
/// </summary>
export class ApiKey {
  static _API_MESSAGE_KEY = 'message';
  static _API_DATA_KEY = 'data';
  static _API_STATUS_KEY = 'status';
  static _API_SUCCESS_KEY = 'success';
  static _API_REQUEST_KEY = 'request';
  static _API_RESPONSE_URL_KEY = 'responseURL';
  static _API_CODE_KEY = 'code';
  static _API_ERROR_KEY = 'error';
  static _API_TOTAL_AMOUNT_KEY = 'totalAmount';
  static _API_COUNT_KEY = 'totalCount';

  static _API_CONTENT_TYPE = 'content-type';
  static _API_ACCEPT = 'Accept';
  static _API_APPLICATION_JSON = 'application/json';
  static _API_XML_HTTP_REQUEST_KEY = 'XMLHttpRequest';
  static _API_FORM_URLENCODED = 'application/x-www-form-urlencoded';
  static _API_MULTIPART_FORM = 'multipart/form-data';
  static _API_POST = 'POST';
  static _API_GET = 'GET';

  static _API_STATUS_OK = 200;
  static _API_APP_VERSION_NOT_COMPATIBLE = 406;
  static _API_INVALID_AUTHORIZATION = 203;
  static _API_STATUS_UNAUTHORIZED = 401;
}

/// <summary>
/// Author: Saitama
/// </summary>
export class InputValidationKey {
  static _REQUIRED_KEY = 'required';
  static _MAX_LENGTH_KEY = 'maxLength';
  static _MIN_LENGTH_KEY = 'minLength';
  static _MAX_KEY = 'max';
  static _MIN_KEY = 'min';
  static _PATTERN = 'pattern';
}

/// <summary>
/// Author : Saitama
/// </summary>
export class Language {
  static _CHINESE = 'zh';
  static _ENGLISH = 'en';
  static _BAHASA_MELAYU = 'ms';
}

/// <summary>
/// Author: Saitama
/// </summary>
export const _LANGUAGE_OPTION = [
  {
    label: 'English',
    prefix: 'EN',
    value: Language._ENGLISH
  },
  {
    label: '简体中文',
    prefix: '中文',
    value: Language._CHINESE
  },
  {
    label: 'Bahasa Melayu',
    prefix: 'BM',
    value: Language._BAHASA_MELAYU
  }
];

/// <summary>
/// Author: Saitama
/// </summary>
export class RegexPattern {
  static _EMAIL_VALIDATION = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  static _ZIP_CODE_VALIDATION = /^\d{5}$/;
  static _TWO_DECIMAL_VALIDATION = /^\d+(\.\d{1,2})?$/;
}

/// <summary>
/// Author : Saitama
/// </summary>
export class Role {
  static _SUPERADMIN = 1;
  static _COMPANY = 2;
  static _SUPPLIER = 3;
  static _JEWELLER = 4;
  static _SALES_PERSON = 5;
}

/// <summary>
/// Author : Saitama
/// </summary>
export class DiamondType {
  static _NATURAL = 1;
  static _LAB_GROWN = 2;
}

/// <summary>
/// Author: Saitama
/// </summary>
export const _DIAMOND_TYPE_OPTION = [
  {
    id: DiamondType._NATURAL,
    label: 'NATURAL',
    value: DiamondType._NATURAL
  },
  {
    id: DiamondType._LAB_GROWN,
    label: 'LAB_GROWN',
    value: DiamondType._LAB_GROWN
  }
];

/// <summary>
/// Author : CK
/// </summary>
export class DiamondClarity {
  static _FL = 1;
  static _IF = 2;
  static _VVS1 = 3;
  static _VVS2 = 4;
  static _VS1 = 5;
  static _VS2 = 6;
  static _SI1 = 7;
  static _SI2 = 8;
  static _SI3 = 9;
  static _I1 = 10;
  static _I2 = 11;
  static _I3 = 12;
}

/// <summary>
/// Author: CK
/// </summary>
export const _DIAMOND_CLARITY_OPTIONS = [
  {
    id: DiamondClarity._FL,
    label: 'FL',
    value: DiamondClarity._FL
  },
  {
    id: DiamondClarity._IF,
    label: 'IF',
    value: DiamondClarity._IF
  },
  {
    id: DiamondClarity._VVS1,
    label: 'VVS1',
    value: DiamondClarity._VVS1
  },
  {
    id: DiamondClarity._VVS2,
    label: 'VVS2',
    value: DiamondClarity._VVS2
  },
  {
    id: DiamondClarity._VS1,
    label: 'VS1',
    value: DiamondClarity._VS1
  },
  {
    id: DiamondClarity._VS2,
    label: 'VS2',
    value: DiamondClarity._VS2
  },
  {
    id: DiamondClarity._SI1,
    label: 'SI1',
    value: DiamondClarity._SI1
  },
  {
    id: DiamondClarity._SI2,
    label: 'SI2',
    value: DiamondClarity._SI2
  },
  {
    id: DiamondClarity._SI3,
    label: 'SI3',
    value: DiamondClarity._SI3
  },
  {
    id: DiamondClarity._I1,
    label: 'I1',
    value: DiamondClarity._I1
  },
  {
    id: DiamondClarity._I2,
    label: 'I2',
    value: DiamondClarity._I2
  },
  {
    id: DiamondClarity._I3,
    label: 'I3',
    value: DiamondClarity._I3
  }
];

/// <summary>
/// Author : CK
/// </summary>
export class DiamondColor {
  static _D = 1;
  static _E = 2;
  static _F = 3;
  static _G = 4;
  static _H = 5;
  static _I = 6;
  static _J = 7;
  static _K = 8;
  static _L = 9;
  static _M = 10;
  static _N = 11;
  static _OP = 12;
  static _QR = 13;
  static _ST = 14;
  static _UV = 15;
  static _WX = 16;
  static _YZ = 17;
}

/// <summary>
/// Author : CK
/// </summary>
export const _DIAMOND_COLOR_OPTIONS = [
  {
    id: DiamondColor._D,
    label: 'D',
    value: DiamondColor._D
  },
  {
    id: DiamondColor._E,
    label: 'E',
    value: DiamondColor._E
  },
  {
    id: DiamondColor._F,
    label: 'F',
    value: DiamondColor._F
  },
  {
    id: DiamondColor._G,
    label: 'G',
    value: DiamondColor._G
  },
  {
    id: DiamondColor._H,
    label: 'H',
    value: DiamondColor._H
  },
  {
    id: DiamondColor._I,
    label: 'I',
    value: DiamondColor._I
  },
  {
    id: DiamondColor._J,
    label: 'J',
    value: DiamondColor._J
  },
  {
    id: DiamondColor._K,
    label: 'K',
    value: DiamondColor._K
  },
  {
    id: DiamondColor._L,
    label: 'L',
    value: DiamondColor._L
  },
  {
    id: DiamondColor._M,
    label: 'M',
    value: DiamondColor._M
  },
  {
    id: DiamondColor._N,
    label: 'N',
    value: DiamondColor._N
  },
  {
    id: DiamondColor._OP,
    label: 'OP',
    value: DiamondColor._OP
  },
  {
    id: DiamondColor._QR,
    label: 'QR',
    value: DiamondColor._QR
  },
  {
    id: DiamondColor._ST,
    label: 'ST',
    value: DiamondColor._ST
  },
  {
    id: DiamondColor._UV,
    label: 'UV',
    value: DiamondColor._UV
  },
  {
    id: DiamondColor._WX,
    label: 'WX',
    value: DiamondColor._WX
  },
  {
    id: DiamondColor._YZ,
    label: 'YZ',
    value: DiamondColor._YZ
  }
];

/// <summary>
/// Author : CK
/// </summary>
export class DiamondFinishCategory {
  static _CUT = 1;
  static _POLISH = 2;
  static _SYMMETRY = 3;
}

/// <summary>
/// Author : CK
/// </summary>
export const _DIAMOND_FINISH_CATEGORY_OPTIONS = [
  {
    id: DiamondFinishCategory._CUT,
    label: 'CUT',
    value: DiamondFinishCategory._CUT
  },
  {
    id: DiamondFinishCategory._POLISH,
    label: 'POLISH',
    value: DiamondFinishCategory._POLISH
  },
  {
    id: DiamondFinishCategory._SYMMETRY,
    label: 'SYMMETRY',
    value: DiamondFinishCategory._SYMMETRY
  }
];

/// <summary>
/// Author : CK
/// </summary>
export class DiamondFinishLevel {
  static _ID = 1;
  static _EX = 2;
  static _VG = 3;
  static _GD = 4;
  static _FR = 5;
  static _PR = 6;
}

/// <summary>
/// Author : CK
/// </summary>
export const _DIAMOND_FINISH_LEVEL_OPTIONS = [
  {
    id: DiamondFinishLevel._ID,
    label: 'ID',
    value: DiamondFinishLevel._ID
  },
  {
    id: DiamondFinishLevel._EX,
    label: 'EX',
    value: DiamondFinishLevel._EX
  },
  {
    id: DiamondFinishLevel._VG,
    label: 'VG',
    value: DiamondFinishLevel._VG
  },
  {
    id: DiamondFinishLevel._GD,
    label: 'GD',
    value: DiamondFinishLevel._GD
  },
  {
    id: DiamondFinishLevel._FR,
    label: 'FR',
    value: DiamondFinishLevel._FR
  },
  {
    id: DiamondFinishLevel._PR,
    label: 'PR',
    value: DiamondFinishLevel._PR
  }
];

/// <summary>
/// Author : CK
/// </summary>
export class DiamondFluorescence {
  static _NONE = 1;
  static _FAINT = 2;
  static _VERY_SLIGHT = 3;
  static _SLIGHT = 4;
  static _MEDIUM = 5;
  static _STRONG = 6;
  static _VERY_STRONG = 7;
}

/// <summary>
/// Author : CK
/// </summary>
export const _DIAMOND_FLUORESCENSE_OPTIONS = [
  {
    id: DiamondFluorescence._NONE,
    label: 'NON',
    value: DiamondFluorescence._NONE
  },
  {
    id: DiamondFluorescence._FAINT,
    label: 'FNT',
    value: DiamondFluorescence._FAINT
  },
  {
    id: DiamondFluorescence._VERY_SLIGHT,
    label: 'VSLT',
    value: DiamondFluorescence._VERY_SLIGHT
  },
  {
    id: DiamondFluorescence._SLIGHT,
    label: 'SLT',
    value: DiamondFluorescence._SLIGHT
  },
  {
    id: DiamondFluorescence._MEDIUM,
    label: 'MED',
    value: DiamondFluorescence._MEDIUM
  },
  {
    id: DiamondFluorescence._STRONG,
    label: 'ST',
    value: DiamondFluorescence._STRONG
  },
  {
    id: DiamondFluorescence._VERY_STRONG,
    label: 'VST',
    value: DiamondFluorescence._VERY_STRONG
  }
];


/// <summary>
/// Author : CK
/// </summary>
export class DiamondGrowthType {
  static _CVD = 1;
  static _HPHT = 2;
  static _UNKNOWN = 3;
}

/// <summary>
/// Author : CK
/// </summary>
export const _DIAMOND_GROWTH_TYPE_OPTIONS = [
  {
    id: DiamondGrowthType._CVD,
    label: 'CVD',
    value: DiamondGrowthType._CVD
  },
  {
    id: DiamondGrowthType._HPHT,
    label: 'HPHT',
    value: DiamondGrowthType._HPHT
  },
  {
    id: DiamondGrowthType._UNKNOWN,
    label: 'UNKNOWN',
    value: DiamondGrowthType._UNKNOWN
  }
];

/// <summary>
/// Author : CK
/// </summary>
export class DiamondLab {
  static _GIA = 1;
  static _IGI = 2;
  static _HRD = 3;
  static _GCAL = 4;
  static _AGS = 5;
  static _OTHER = 6;
}

/// <summary>
/// Author : CK
/// </summary>
export const _DIAMOND_LAB_OPTIONS = [
  {
    id: DiamondLab._GIA,
    label: 'GIA',
    value: DiamondLab._GIA
  },
  {
    id: DiamondLab._IGI,
    label: 'IGI',
    value: DiamondLab._IGI
  },
  {
    id: DiamondLab._HRD,
    label: 'HRD',
    value: DiamondLab._HRD
  },
  {
    id: DiamondLab._GCAL,
    label: 'GCAL',
    value: DiamondLab._GCAL
  },
  {
    id: DiamondLab._AGS,
    label: 'AGS',
    value: DiamondLab._AGS
  },
  {
    id: DiamondLab._OTHER,
    label: 'OTHER',
    value: DiamondLab._OTHER
  }
];

/// <summary>
/// Author : CK
/// </summary>
export class DiamondLocation {
  static _INDIA = 1;
  static _HONGKONG = 2;
  static _USA = 3;
  static _UAE = 4;
  static _UK = 5;
  static _ISRAEL = 6;
  static _BELGIUM = 7;
  static _OTHER = 8;
}

/// <summary>
/// Author : CK
/// </summary>
export const _DIAMOND_LOCATION_OPTIONS = [
  {
    id: DiamondLocation._INDIA,
    label: 'INDIA',
    value: DiamondLocation._INDIA
  },
  {
    id: DiamondLocation._HONGKONG,
    label: 'HONGKONG',
    value: DiamondLocation._HONGKONG
  },
  {
    id: DiamondLocation._USA,
    label: 'USA',
    value: DiamondLocation._USA
  },
  {
    id: DiamondLocation._UAE,
    label: 'UAE',
    value: DiamondLocation._UAE
  },
  {
    id: DiamondLocation._UK,
    label: 'UK',
    value: DiamondLocation._UK
  },
  {
    id: DiamondLocation._ISRAEL,
    label: 'ISRAEL',
    value: DiamondLocation._ISRAEL
  },
  {
    id: DiamondLocation._BELGIUM,
    label: 'BELGIUM',
    value: DiamondLocation._BELGIUM
  },
  {
    id: DiamondLocation._OTHER,
    label: 'OTHER',
    value: DiamondLocation._OTHER
  }
];

/// <summary>
/// Author : CK
/// </summary>
export class DiamondShape {
  static _ROUND = 1;
  static _PRINCESS = 2;
  static _OVAL = 3;
  static _HEART = 4;
  static _CUSHION = 5;
  static _EMERALD = 6;
  static _MARQUISE = 7;
  static _CUSHION_MODIFIED = 8;
  static _PEAR = 9;
  static _ASSCHER = 10;
  static _RADIANT = 11;
  static _SQUARE_RADIANT = 12;
  static _TRIANGLE = 13;
  static _TRILLIANT = 14;
  static _OTHER = 15;
}

/// <summary>
/// Author : CK
/// </summary>
export const _DIAMOND_SHAPE_OPTIONS = [
  {
    id: DiamondShape._ROUND,
    label: 'ROUND',
    value: DiamondShape._ROUND,
    image: '/diamonds/round.svg',
    defaultImg: '/diamond-sample/round.png'
  },
  {
    id: DiamondShape._PRINCESS,
    label: 'PRINCESS',
    value: DiamondShape._PRINCESS,
    image: '/diamonds/princess.svg',
    defaultImg: '/diamond-sample/princess.png'
  },
  {
    id: DiamondShape._OVAL,
    label: 'OVAL',
    value: DiamondShape._OVAL,
    image: '/diamonds/oval.svg',
    defaultImg: '/diamond-sample/oval.png'
  },
  {
    id: DiamondShape._HEART,
    label: 'HEART',
    value: DiamondShape._HEART,
    image: '/diamonds/heart.svg',
    defaultImg: '/diamond-sample/heart.png'
  },
  {
    id: DiamondShape._CUSHION,
    label: 'CUSHION',
    value: DiamondShape._CUSHION,
    image: '/diamonds/cushion.svg',
    defaultImg: '/diamond-sample/cushion.png'
  },
  {
    id: DiamondShape._EMERALD,
    label: 'EMERALD',
    value: DiamondShape._EMERALD,
    image: '/diamonds/emerald.svg',
    defaultImg: '/diamond-sample/emerald.png'
  },
  {
    id: DiamondShape._MARQUISE,
    label: 'MARQUISE',
    value: DiamondShape._MARQUISE,
    image: '/diamonds/marquise.svg',
    defaultImg: '/diamond-sample/marquise.png'
  },
  {
    id: DiamondShape._CUSHION_MODIFIED,
    label: 'CUSHION_MODIFIED',
    value: DiamondShape._CUSHION_MODIFIED,
    image: '/diamonds/cushion-modified.svg',
    defaultImg: '/diamond-sample/cushion-modified.png'
  },
  {
    id: DiamondShape._PEAR,
    label: 'PEAR',
    value: DiamondShape._PEAR,
    image: '/diamonds/pear.svg',
    defaultImg: '/diamond-sample/pear.png'
  },
  {
    id: DiamondShape._ASSCHER,
    label: 'ASSCHER',
    value: DiamondShape._ASSCHER,
    image: '/diamonds/asscher.svg',
    defaultImg: '/diamond-sample/asscher.png'
  },
  {
    id: DiamondShape._RADIANT,
    label: 'RADIANT',
    value: DiamondShape._RADIANT,
    image: '/diamonds/radiant.svg',
    defaultImg: '/diamond-sample/radiant.png'
  },
  {
    id: DiamondShape._SQUARE_RADIANT,
    label: 'SQUARE_RADIANT',
    value: DiamondShape._SQUARE_RADIANT,
    image: '/diamonds/square-radiant.svg',
    defaultImg: '/diamond-sample/square-radiant.png'
  },
  {
    id: DiamondShape._TRIANGLE,
    label: 'TRIANGLE',
    value: DiamondShape._TRIANGLE,
    image: '/diamonds/triangle.svg',
    defaultImg: '/diamond-sample/triangle.png'
  },
  {
    id: DiamondShape._TRILLIANT,
    label: 'TRILLIANT',
    value: DiamondShape._TRILLIANT,
    image: '/diamonds/trilliant.svg',
    defaultImg: '/diamond-sample/trilliant.png'
  },
  {
    id: DiamondShape._OTHER,
    label: 'OTHER',
    value: DiamondShape._OTHER,
    image: '/diamonds/other.svg',
    defaultImg: '/diamond-sample/round.png'
  }
];

/// <summary>
/// Author : CK
/// </summary>
export class DiamondTingeCategory {
  static _EYE_CLEAN = 1;
  static _SHADE = 2;
  static _MILKY = 3;
}

/// <summary>
/// Author : CK
/// </summary>
export const _DIAMOND_TINGE_CATEGORY_OPTIONS = [
  {
    id: DiamondTingeCategory._EYE_CLEAN,
    label: 'EYE_CLEAN',
    value: DiamondTingeCategory._EYE_CLEAN
  },
  {
    id: DiamondTingeCategory._SHADE,
    label: 'SHADE',
    value: DiamondTingeCategory._SHADE
  },
  {
    id: DiamondTingeCategory._MILKY,
    label: 'MILKY',
    value: DiamondTingeCategory._MILKY
  }
];

/// <summary>
/// Author : CK
/// </summary>
export class DiamondTingeEyeClean {
  static _EYE_CLEAN = 1;
  static _NO = 2;
  static _OTHER = 3;
}

/// <summary>
/// Author : CK
/// </summary>
export const _DIAMOND_TINGE_EYE_CLEAN_OPTIONS = [
  {
    id: DiamondTingeEyeClean._EYE_CLEAN,
    label: 'FLAWLESS',
    value: DiamondTingeEyeClean._EYE_CLEAN
  },
  {
    id: DiamondTingeEyeClean._NO,
    label: 'NO_FLAWLESS',
    value: DiamondTingeEyeClean._NO
  },
  {
    id: DiamondTingeEyeClean._OTHER,
    label: 'FLAW',
    value: DiamondTingeEyeClean._OTHER
  }
];

/// <summary>
/// Author : CK
/// </summary>
export class DiamondTingeMilky {
  static _NO_MILKY = 1;
  static _LIGHT_MILKY = 2;
  static _MILKY = 3;
  static _UNKNOWN = 4;
}

/// <summary>
/// Author : CK
/// </summary>
export const _DIAMOND_TINGE_MILKY_OPTIONS = [
  {
    id: DiamondTingeMilky._NO_MILKY,
    label: 'NO_MILKY',
    value: DiamondTingeMilky._NO_MILKY
  },
  {
    id: DiamondTingeMilky._LIGHT_MILKY,
    label: 'LIGHT_MILKY',
    value: DiamondTingeMilky._LIGHT_MILKY
  },
  {
    id: DiamondTingeMilky._MILKY,
    label: 'MILKY',
    value: DiamondTingeMilky._MILKY
  },
  {
    id: DiamondTingeMilky._UNKNOWN,
    label: 'UNKNOWN',
    value: DiamondTingeMilky._UNKNOWN
  }
];

/// <summary>
/// Author : CK
/// </summary>
export class DiamondTingeShade {
  static _NONE = 1;
  static _LIGHT_BROWN = 2;
  static _BROWN = 3;
  static _LIGHT_GREEN = 4;
  static _GREEN = 5;
  static _GREY = 6;
  static _BLACK = 7;
  static _PINK = 8;
  static _BLUE = 9;
  static _MIX_TINGE = 10;
  static _UNKNOWN = 11;
}

/// <summary>
/// Author : CK
/// </summary>
export const _DIAMOND_TINGE_SHADE_OPTIONS = [
  {
    id: DiamondTingeShade._NONE,
    label: 'NONE',
    value: DiamondTingeShade._NONE
  },
  {
    id: DiamondTingeShade._LIGHT_BROWN_NONE,
    label: 'LIGHT_BROWN',
    value: DiamondTingeShade._LIGHT_BROWN
  },
  {
    id: DiamondTingeShade._BROWN_NONE,
    label: 'BROWN',
    value: DiamondTingeShade._BROWN
  },
  {
    id: DiamondTingeShade._LIGHT_GREEN_NONE,
    label: 'LIGHT_GREEN',
    value: DiamondTingeShade._LIGHT_GREEN
  },
  {
    id: DiamondTingeShade._GREEN_NONE,
    label: 'GREEN',
    value: DiamondTingeShade._GREEN
  },
  {
    id: DiamondTingeShade._GREY_NONE,
    label: 'GREY',
    value: DiamondTingeShade._GREY
  },
  {
    id: DiamondTingeShade._BLACK_NONE,
    label: 'BLACK',
    value: DiamondTingeShade._BLACK
  },
  {
    id: DiamondTingeShade._PINK_NONE,
    label: 'PINK',
    value: DiamondTingeShade._PINK
  },
  {
    id: DiamondTingeShade._BLUE_NONE,
    label: 'BLUE',
    value: DiamondTingeShade._BLUE
  },
  {
    id: DiamondTingeShade._MIX_TINGE_NONE,
    label: 'MIX_TINGE',
    value: DiamondTingeShade._MIX_TINGE
  },
  {
    id: DiamondTingeShade._UNKNOWN_NONE,
    label: 'UNKNOWN',
    value: DiamondTingeShade._UNKNOWN
  }
];

/// <summary>
/// Author : CK
/// </summary>
export class DiamondTreatment {
  static _AS_GROWN = 1;
  static _LASER_DRILLED = 2;
  static _IRADIATED = 3;
  static _CLARITY_ENHANCED = 4;
  static _COLOR_ENHANCE = 5;
  static _HIGH_PRESSURE_HIGH_TEMPERATURE = 6;
  static _OTHER = 7;
  static _NONE = 8;
}

/// <summary>
/// Author : CK
/// </summary>
export const _DIAMOND_TREATMENT_OPTIONS = [
  {
    id: DiamondTreatment._AS_GROWN,
    label: 'AS_GROWN',
    value: DiamondTreatment._AS_GROWN
  },
  {
    id: DiamondTreatment._LASER_DRILLED,
    label: 'LASER_DRILLED',
    value: DiamondTreatment._LASER_DRILLED
  },
  {
    id: DiamondTreatment._IRADIATED,
    label: 'IRADIATED',
    value: DiamondTreatment._IRADIATED
  },
  {
    id: DiamondTreatment._CLARITY_ENHANCED,
    label: 'CLARITY_ENHANCED',
    value: DiamondTreatment._CLARITY_ENHANCED
  },
  {
    id: DiamondTreatment._COLOR_ENHANCE,
    label: 'COLOR_ENHANCE',
    value: DiamondTreatment._COLOR_ENHANCE
  },
  {
    id: DiamondTreatment._HIGH_PRESSURE_HIGH_TEMPERATURE,
    label: 'HIGH_PRESSURE_HIGH_TEMPERATURE',
    value: DiamondTreatment._HIGH_PRESSURE_HIGH_TEMPERATURE
  },
  {
    id: DiamondTreatment._OTHER,
    label: 'OTHER',
    value: DiamondTreatment._OTHER
  },
  {
    id: DiamondTreatment._NONE,
    label: 'NONE',
    value: DiamondTreatment._NONE
  }
];

/// <summary>
/// Author : CK
/// </summary>
export class CommonStatus {
  static _INVALID = -1;
  static _PENDING = 0;
  static _ACTIVE = 1;
}

/// <summary>
/// Author : CK
/// </summary>
export class DiamondStockStatus {
  static _ALL_PUBLISHED = -1;
  static _PENDING_APPROVAL = 0;
  static _PUBLISHED = 1;
  static _ON_HOLD = 2;
  static _ORDERED = 3;
  static _PAYMENT_MADE = 4;
  static _QUALITY_CHECK = 5;
  static _PREPARE_DELIVERY = 6;
  static _SHIPED = 7;
  static _RECEIVED = 8;
}

/// <summary>
/// Author : CK
/// </summary>
export const _DIAMOND_STOCK_STATUS_OPTIONS = [
  {
    id: DiamondStockStatus._PENDING_APPROVAL,
    label: 'PENDING_APPROVAL',
    value: DiamondStockStatus._PENDING_APPROVAL
  },
  {
    id: DiamondStockStatus._PUBLISHED,
    label: 'PUBLISHED',
    value: DiamondStockStatus._PUBLISHED
  },
  {
    id: DiamondStockStatus._ON_HOLD,
    label: 'ON_HOLD',
    value: DiamondStockStatus._ON_HOLD
  },
  {
    id: DiamondStockStatus._ORDERED,
    label: 'ORDERED',
    value: DiamondStockStatus._ORDERED
  },
  {
    id: DiamondStockStatus._PAYMENT_MADE,
    label: 'PAYMENT_MADE',
    value: DiamondStockStatus._PAYMENT_MADE
  },
  {
    id: DiamondStockStatus._QUALITY_CHECK,
    label: 'QUALITY_CHECK',
    value: DiamondStockStatus._QUALITY_CHECK
  },
  {
    id: DiamondStockStatus._PREPARE_DELIVERY,
    label: 'PREPARE_DELIVERY',
    value: DiamondStockStatus._PREPARE_DELIVERY
  },
  {
    id: DiamondStockStatus._SHIPED,
    label: 'SHIPED',
    value: DiamondStockStatus._SHIPED
  },
  {
    id: DiamondStockStatus._RECEIVED,
    label: 'RECEIVED',
    value: DiamondStockStatus._RECEIVED
  }
];

/// <summary>
/// Author : Saitama
/// </summary>
export class SupplierProfileStatus {
  static _PENDING_APPROVAL = 0;
  static _SUCCESS = 1;
  static _EMPTY_PROFILE = -1;
}

/// <summary>
/// Author : CK
/// </summary>
export class DiamondAvailability {
  static _AVAILABLE = 1;
  static _HOLD = -1;
  static _MEMO = -2;
  static _BUSY = -3;
  static _NOT_AVAILABLE = -4;
}

/// <summary>
/// Author : CK
/// </summary>
export const _DIAMOND_AVAILABILITY_OPTIONS = [
  {
    id: DiamondAvailability._AVAILABLE,
    label: 'AVAILABLE',
    value: DiamondAvailability._AVAILABLE
  },
  {
    id: DiamondAvailability._HOLD,
    label: 'HOLD',
    value: DiamondAvailability._HOLD
  },
  {
    id: DiamondAvailability._MEMO,
    label: 'MEMO',
    value: DiamondAvailability._MEMO
  },
  {
    id: DiamondAvailability._BUSY,
    label: 'BUSY',
    value: DiamondAvailability._BUSY
  },
  {
    id: DiamondAvailability._NOT_AVAILABLE,
    label: 'NOT_AVAILABLE',
    value: DiamondAvailability._NOT_AVAILABLE
  }
];

/// <summary>
/// Author : CK
/// </summary>
export class DiamondFancyColor {
  static _BLACK = 1;
  static _BLUE = 2;
  static _BROWN = 3;
  static _CHAMELEON = 4;
  static _COGNAC = 5;
  static _GRAY = 6;
  static _GREEN = 7;
  static _ORANGE = 8;
  static _PINK = 9;
  static _PURPLE = 10;
  static _RED = 11;
  static _VIOLET = 12;
  static _YELLOW = 13;
  static _WHITE = 14;
  static _OTHER = 15;
}

/// <summary>
/// Author : CK
/// </summary>
export const _DIAMOND_FANCY_COLOR_OPTIONS = [
  {
    id: DiamondFancyColor._BLACK,
    label: "BLACK",
    value: DiamondFancyColor._BLACK
  },
  {
    id: DiamondFancyColor._BLUE,
    label: "BLUE",
    value: DiamondFancyColor._BLUE
  },
  {
    id: DiamondFancyColor._BROWN,
    label: "BROWN",
    value: DiamondFancyColor._BROWN
  },
  {
    CHAMid: DiamondFancyColor._CHAMELEON,
    label: "CHAMELEON",
    value: DiamondFancyColor._CHAMELEON
  },
  {
    Cid: DiamondFancyColor._COGNAC,
    label: "COGNAC",
    value: DiamondFancyColor._COGNAC
  },
  {
    id: DiamondFancyColor._GRAY,
    label: "GRAY",
    value: DiamondFancyColor._GRAY
  },
  {
    id: DiamondFancyColor._GREEN,
    label: "GREEN",
    value: DiamondFancyColor._GREEN
  },
  {
    Oid: DiamondFancyColor._ORANGE,
    label: "ORANGE",
    value: DiamondFancyColor._ORANGE
  },
  {
    id: DiamondFancyColor._PINK,
    label: "PINK",
    value: DiamondFancyColor._PINK
  },
  {
    Pid: DiamondFancyColor._PURPLE,
    label: "PURPLE",
    value: DiamondFancyColor._PURPLE
  },
  {
    id: DiamondFancyColor._RED,
    label: "RED",
    value: DiamondFancyColor._RED
  },
  {
    Vid: DiamondFancyColor._VIOLET,
    label: "VIOLET",
    value: DiamondFancyColor._VIOLET
  },
  {
    Yid: DiamondFancyColor._YELLOW,
    label: "YELLOW",
    value: DiamondFancyColor._YELLOW
  },
  {
    id: DiamondFancyColor._WHITE,
    label: "WHITE",
    value: DiamondFancyColor._WHITE
  },
  {
    id: DiamondFancyColor._OTHER,
    label: "OTHER",
    value: DiamondFancyColor._OTHER
  }
];

/// <summary>
/// Author : CK
/// </summary>
export class DiamondFancyColorIntensity {
  static _FAINT = 1;
  static _VERY_LIGHT = 2;
  static _LIGHT = 3;
  static _FANCY_LIGHT = 4;
  static _FANCY_DARK = 5;
  static _FANCY_INTENSE = 6;
  static _FANCY_VIVID = 7;
  static _FANCY_DEEP = 8;
}

/// <summary>
/// Author : CK
/// </summary>
export const _DIAMOND_FANCY_COLOR_INTENSITY_OPTIONS = [
  {
    id: DiamondFancyColorIntensity._FAINT,
    label: "FAINT",
    value: DiamondFancyColorIntensity._FAINT
  },
  {
    id: DiamondFancyColorIntensity._VERY_LIGHT,
    label: "VERY_LIGHT",
    value: DiamondFancyColorIntensity._VERY_LIGHT
  },
  {
    id: DiamondFancyColorIntensity._LIGHT,
    label: "LIGHT",
    value: DiamondFancyColorIntensity._LIGHT
  },
  {
    id: DiamondFancyColorIntensity._FANCY_LIGHT,
    label: "FANCY_LIGHT",
    value: DiamondFancyColorIntensity._FANCY_LIGHT
  },
  {
    id: DiamondFancyColorIntensity._FANCY_DARK,
    label: "FANCY_DARK",
    value: DiamondFancyColorIntensity._FANCY_DARK
  },
  {
    id: DiamondFancyColorIntensity._FANCY_INTENSE,
    label: "FANCY_INTENSE",
    value: DiamondFancyColorIntensity._FANCY_INTENSE
  },
  {
    id: DiamondFancyColorIntensity._FANCY_VIVID,
    label: "FANCY_VIVID",
    value: DiamondFancyColorIntensity._FANCY_VIVID
  },
  {
    id: DiamondFancyColorIntensity._FANCY_DEEP,
    label: "FANCY_DEEP",
    value: DiamondFancyColorIntensity._FANCY_DEEP
  }
];

/// <summary>
/// Author : CK
/// </summary>
export class DiamondFancyColorOvertone {
  static _NONE = 1;
  static _YELLOW = 2;
  static _YELLOWISH = 3;
  static _PINK = 4;
  static _PINKISH = 5;
  static _BLUE = 6;
  static _BLUISH = 7;
  static _RED = 8;
  static _REDDISH = 9;
  static _GREEN = 10;
  static _GREENISH = 11;
  static _PURPLE = 12;
  static _PURPLISH = 13;
  static _ORANGE = 14;
  static _ORANGEY = 15;
  static _VIOLET = 16;
  static _GRAY = 17;
  static _GRAYISH = 18;
  static _BLACK = 19;
  static _BROWN = 20;
  static _BROWNISH = 21;
  static _CHAMPANGE = 22;
  static _COGNAC = 23;
  static _CHAMELEON = 24;
  static _VIOLETISH = 25;
  static _WHITE = 26;
  static _OTHER = 27;
}

/// <summary>
/// Author : CK
/// </summary>
export const _DIAMOND_FANCY_COLOR_OVERTONE_OPTIONS = [
  {
    id: DiamondFancyColorOvertone._NONE,
    label: "NONE",
    value: DiamondFancyColorOvertone._NONE
  },
  {
    id: DiamondFancyColorOvertone._YELLOW,
    label: "YELLOW",
    value: DiamondFancyColorOvertone._YELLOW
  },
  {
    id: DiamondFancyColorOvertone._YELLOWISH,
    label: "YELLOWISH",
    value: DiamondFancyColorOvertone._YELLOWISH
  },
  {
    id: DiamondFancyColorOvertone._PINK,
    label: "PINK",
    value: DiamondFancyColorOvertone._PINK
  },
  {
    id: DiamondFancyColorOvertone._PINKISH,
    label: "PINKISH",
    value: DiamondFancyColorOvertone._PINKISH
  },
  {
    id: DiamondFancyColorOvertone._BLUE,
    label: "BLUE",
    value: DiamondFancyColorOvertone._BLUE
  },
  {
    id: DiamondFancyColorOvertone._BLUISH,
    label: "BLUISH",
    value: DiamondFancyColorOvertone._BLUISH
  },
  {
    id: DiamondFancyColorOvertone._RED,
    label: "RED",
    value: DiamondFancyColorOvertone._RED
  },
  {
    id: DiamondFancyColorOvertone._REDDISH,
    label: "REDDISH",
    value: DiamondFancyColorOvertone._REDDISH
  },
  {
    id: DiamondFancyColorOvertone._GREEN,
    label: "GREEN",
    value: DiamondFancyColorOvertone._GREEN
  },
  {
    id: DiamondFancyColorOvertone._GREENISH,
    label: "GREENISH",
    value: DiamondFancyColorOvertone._GREENISH
  },
  {
    id: DiamondFancyColorOvertone._PURPLE,
    label: "PURPLE",
    value: DiamondFancyColorOvertone._PURPLE
  },
  {
    id: DiamondFancyColorOvertone._PURPLISH,
    label: "PURPLISH",
    value: DiamondFancyColorOvertone._PURPLISH
  },
  {
    id: DiamondFancyColorOvertone._ORANGE,
    label: "ORANGE",
    value: DiamondFancyColorOvertone._ORANGE
  },
  {
    id: DiamondFancyColorOvertone._ORANGEY,
    label: "ORANGEY",
    value: DiamondFancyColorOvertone._ORANGEY
  },
  {
    id: DiamondFancyColorOvertone._VIOLET,
    label: "VIOLET",
    value: DiamondFancyColorOvertone._VIOLET
  },
  {
    id: DiamondFancyColorOvertone._GRAY,
    label: "GRAY",
    value: DiamondFancyColorOvertone._GRAY
  },
  {
    id: DiamondFancyColorOvertone._GRAYISH,
    label: "GRAYISH",
    value: DiamondFancyColorOvertone._GRAYISH
  },
  {
    id: DiamondFancyColorOvertone._BLACK,
    label: "BLACK",
    value: DiamondFancyColorOvertone._BLACK
  },
  {
    id: DiamondFancyColorOvertone._BROWN,
    label: "BROWN",
    value: DiamondFancyColorOvertone._BROWN
  },
  {
    id: DiamondFancyColorOvertone._BROWNISH,
    label: "BROWNISH",
    value: DiamondFancyColorOvertone._BROWNISH
  },
  {
    id: DiamondFancyColorOvertone._CHAMPANGE,
    label: "CHAMPANGE",
    value: DiamondFancyColorOvertone._CHAMPANGE
  },
  {
    id: DiamondFancyColorOvertone._COGNAC,
    label: "COGNAC",
    value: DiamondFancyColorOvertone._COGNAC
  },
  {
    id: DiamondFancyColorOvertone._CHAMELEON,
    label: "CHAMELEON",
    value: DiamondFancyColorOvertone._CHAMELEON
  },
  {
    id: DiamondFancyColorOvertone._VIOLETISH,
    label: "VIOLETISH",
    value: DiamondFancyColorOvertone._VIOLETISH
  },
  {
    id: DiamondFancyColorOvertone._WHITE,
    label: "WHITE",
    value: DiamondFancyColorOvertone._WHITE
  },
  {
    id: DiamondFancyColorOvertone._OTHER,
    label: "OTHER",
    value: DiamondFancyColorOvertone._OTHER
  }
];

// <summary>
/// Author : CK
/// </summary>
export class DiamondColorType {
  static _WHITE = 0;
  static _FANCY_COLOR = 1;
}

/// <summary>
/// Author : CK
/// </summary>
export class CompanyProfileStatus {
  static _PENDING_PROFILE = -1;
  static _REJECTED = -1;
  static _PENDING_APPROVAL = 0;
  static _APPROVED = 1;
}

/// <summary>
/// Author : Saitama
/// </summary>
export class JewellerKycStatus {
  static _REJECTED = 0;
  static _APPROVED = 1;
  static _PENDING = -1;
}

/// <summary>
/// Author : Saitama
/// </summary>
export class MarkupPriceType {
  static _BY_CARAT = 1;
  static _BY_PRICE = 2;
}

/// <summary>
/// Author : Saitama
/// </summary>
export const _MARKUP_PRICE_OPTIONS = [
  {
    label: "CARAT",
    value: MarkupPriceType._BY_CARAT
  },
  {
    label: "PRICE",
    value: MarkupPriceType._BY_PRICE
  },
];

/// <summary>
/// Author : Saitama
/// </summary>
export const _MARKUP_STYLE_OPTIONS = [
  {
    label: "ABSOLUTE",
    value: false
  },
  {
    label: "PERCENTAGE",
    value: true
  },
];

/// <summary>
/// Author : Saitama
/// </summary>
export class OrderStatus {
  static _REJECTED = -1;
  static _PENDING = 1;
  static _READY_FOR_INVOICE = 2;
  static _PENDING_PAYMENT = 3;
  static _PENDING_SHIPPING = 4;
  static _SHIPPED = 5;
}