import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Flex,
  Button,
  FormControl,
  Text
} from "@chakra-ui/react";
import { showCustomDialog } from "../../../application/action/app_action";
import Card from "../../../components/card/Card";
import CustomSelect from "../../../components/custom-select";
import CustomGroupCheckBox from "../../../components/custom-group-checkbox";
import { _DIAMOND_TYPE_OPTION, ApiKey } from "../../../common/constant";
import ApiEngine from "../../../common/api-engine";
import Routes from "../../../common/api_routes";
import { checkIsLoggedIn } from "../../../application/action/auth_action";
import AdminNavbarLinks from '../../../components/navbar/NavbarLinksAdmin';

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
  const _USER_DATA = useSelector((state) => state.authState.userData);
  const { t } = useTranslation();
  const { control, handleSubmit, errors, setValue, watch } = useForm();
  var _dispatch = useDispatch();
  const _OWNED_DIAMOND_QUANTITY = [
    {
      label: '1 - 100',
      value: '1@100'
    },
    {
      label: '101 - 1000',
      value: '101@1000'
    },
    {
      label: '1001 - 2000',
      value: '1001@2000'
    },
    {
      label: '2001 - 3000',
      value: '2001@3000'
    },
    {
      label: '4000_ABOVE',
      value: '4000@10000'
    },
  ]

  /// <summary>
  /// Author: Saitama
  /// </summary>
  const submitForm = async (data, e) => {
    try {
      let params = {
        userId: _USER_DATA.user.id,
        minDiamondRange: parseInt(data.diamondRange.split('@')[0]),
        maxDiamondRange: parseInt(data.diamondRange.split('@')[1]),
        diamondTypes: data.diamondTypeList.join(',')
      };
      var responseJson = await ApiEngine.post(Routes._SUPPLIER_INFO, params);

      if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }

      _dispatch(checkIsLoggedIn());
    }
    catch (err) {
      _dispatch(
        showCustomDialog({
          success: false,
          content: err,
        })
      );
    }
  };

  return (
    <Flex flexDirection={'column'}>
      <Box padding='20px' pb={0}>
        <AdminNavbarLinks
          fixed={false}
        />
      </Box>
      <Box mx='auto' p={{ base: '20px', md: '30px' }} pe='20px' minH='100vh' pt='50px' alignContent='center'>
        <form onSubmit={handleSubmit(submitForm)}>
          <Card
            align='center'
            direction='column'
            w='100%'
            mb='0px'>
            <FormControl maxW={{ base: '100%', md: '50%' }} alignSelf='center'>
              <Text fontSize='2xl' as={'b'}>{t('YOU_RE_ALMOST_DONE')}</Text>
              <br />
              <Text fontSize='lg' fontStyle={'italic'} marginBottom={'25px'}>{t('THANKS_FOR_CHOOSING_CASTIEL_SUPPLIER_CENTER')}</Text>
              <Controller
                control={control}
                name="diamondRange"
                defaultValue={""}
                render={({ onChange, value, name }) => (
                  <CustomSelect
                    id={name}
                    onChange={(value) => onChange(value)}
                    label={t('HOW_MANY_DIAMOND_DO_YOU_HAVE')}
                    value={value}
                    errors={errors}
                    showEmptyOption
                    options={_OWNED_DIAMOND_QUANTITY}
                  />
                )}
                rules={{
                  required: true,
                  message: "REQUIRED",
                }}
              />
              <Controller
                control={control}
                name="diamondTypeList"
                defaultValue={[]}
                render={({ onChange, value, name }) => (
                  <CustomGroupCheckBox
                    selectOneOnly
                    id={name}
                    onChange={(value) => onChange(value)}
                    label={t('WHAT_TYPE_OF_DIAMOND_DO_YOU_SELL')}
                    value={value}
                    errors={errors}
                    options={_DIAMOND_TYPE_OPTION} />
                )}
                rules={{
                  validate: value => Array.isArray(value) && value.length > 0 || 'REQUIRED_INFO_MSG'
                }}
              />
              <Flex justifyContent={'flex-end'}>
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  mb="24px"
                  type="submit"
                >
                  {t('SUBMIT')}
                </Button>
              </Flex>
            </FormControl>
          </Card>
        </form>
      </Box>
    </Flex>
  );
}
