import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Checkbox, Stack, Container, Text, FormLabel, SimpleGrid } from '@chakra-ui/react';
import { getValidationMessage } from "../common/util";

/// <summary>
/// Author: Saitama
/// </summary>
const CustomGroupCheckBox = React.forwardRef((props, ref) => {
    const {
        id,
        value = [],
        onChange,
        label,
        errors,
        patternErrorTxt,
        options = [],
        style,
        size = 'lg',
        isDisabled = false,
        selectOneOnly = false,
    } = props;
    const { t } = useTranslation();

    return (
        <Stack mb={'20px'} {...style}>
            {
                !selectOneOnly ?
                    <Checkbox
                        isDisabled={isDisabled}
                        isChecked={value.length == options.length}
                        isIndeterminate={value.length != options.length && value.length > 0}
                        onChange={(e) => {
                            let newCheckedData = [];

                            if (e.target.checked) {
                                newCheckedData = options.map(item => item.id);
                            }

                            onChange(newCheckedData);
                        }}
                        isplay={"flex"}
                        ms={"4px"}
                        fontSize={"md"}
                        fontWeight={"500"}
                        mb={"3px"}
                        size={size}
                        colorScheme={'brand'}>
                        {t(label)}
                    </Checkbox> :
                    <FormLabel
                        display={"flex"}
                        ms={"4px"}
                        fontSize={"md"}
                        fontWeight={"500"}
                        mb={"3px"}
                    >
                        {t(label)}
                    </FormLabel>
            }
            <Container textAlign={'left'} maxWidth={'auto'}>
                <SimpleGrid columns={2}>
                    {
                        options.map((item, index) => {
                            return (
                                <Checkbox
                                    key={index}
                                    isDisabled={isDisabled}
                                    isChecked={value.includes(item.id)}
                                    onChange={(e) => {
                                        let newCheckedData = [...value];

                                        if (e.target.checked) {
                                            if (selectOneOnly) {
                                                newCheckedData = [item.id];
                                            }
                                            else {
                                                newCheckedData.push(item.id);
                                            }
                                        }
                                        else {
                                            newCheckedData = newCheckedData.filter(c => c != item.id);
                                        }

                                        onChange(newCheckedData);
                                    }}
                                    size={size}
                                    colorScheme={'brand'}
                                    ml={'10px'}>
                                    {t(item.label)}
                                </Checkbox>
                            )
                        })
                    }
                </SimpleGrid>
            </Container>
            {
                errors && errors[id] &&
                <Text color={'red'} textAlign={'left'}>{t(getValidationMessage(errors[id], patternErrorTxt))}</Text>
            }
        </Stack>
    )
});

export default CustomGroupCheckBox;