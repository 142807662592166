import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
    Box,
    Flex,
    Button,
    Text,
    Link,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Divider,
    AbsoluteCenter,
    Stack
} from "@chakra-ui/react";
import {
    Role,
    _DIAMOND_SHAPE_OPTIONS,
    _DIAMOND_CLARITY_OPTIONS,
    _DIAMOND_COLOR_OPTIONS,
    _DIAMOND_FINISH_LEVEL_OPTIONS,
    _DIAMOND_FLUORESCENSE_OPTIONS,
    _DIAMOND_LAB_OPTIONS,
    _DIAMOND_LOCATION_OPTIONS,
    _DIAMOND_TINGE_SHADE_OPTIONS,
    _DIAMOND_TINGE_MILKY_OPTIONS,
    _DIAMOND_TINGE_EYE_CLEAN_OPTIONS,
    _DIAMOND_TREATMENT_OPTIONS,
    _DIAMOND_TYPE_OPTION,
    ApiKey,
    CommonStatus
} from "../common/constant";
import moment from "moment";
import { createMultiPartFormBody, stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat, isAdminPortal, getLabCertificateTrackUrl } from "../common/util";
import { showCustomDialog } from "../application/action/app_action";
import CustomMediaUploader from "components/custom-media-uploader";
import { RiDeleteBin6Line } from "react-icons/ri";
import ApiEngine from "../common/api-engine";
import { WebUrl } from "../routes";
import Routes from "../common/api_routes";
import { useDispatch, useSelector } from "react-redux";
import DiamondMedia from './diamond-media';

/// <summary>
/// Author: CK
/// </summary>
const DiamondDetail = React.forwardRef((props, ref) => {
    const {
        item,
        ...restProps
    } = props;
    const { t } = useTranslation();
    const _USER_DATA = useSelector((state) => state.authState.userData);

    return (
        <Box className="diamond-detail-accordion">
            <Flex>
                <Flex flex={5}>
                    <Flex flex={1} flexDir={'column'}>
                        <DiamondMedia item={item} medias={item?.diamondMedias} />
                    </Flex>
                    <Flex marginX={2} />
                    <Flex flex={1} flexDir={'column'} className="price-detail-container">
                        <Flex>
                            <Link
                                fontSize={'13px'}
                                color='gold.600'
                                mr='10px'
                                href={getLabCertificateTrackUrl(item?.labId, item?.certificateId)} isExternal>
                                {_DIAMOND_LAB_OPTIONS.find(i => i.id == item?.labId)?.label}-{item?.certificateId} <ExternalLinkIcon mx='2px' />
                            </Link>
                            <Text
                                fontSize={'13px'}
                            >
                                {t('STOCK_NO')}: {item?.stockNo}
                            </Text>
                        </Flex>
                        <Text mt='15px' mb='15px' fontWeight={700} fontSize={'18px'}>{item?.label}</Text>
                        <Flex mb={"10px"}>
                            <Flex mr={5}>
                                <Text color="secondaryGray.500" fontSize={'13px'} mr={1}>{t("SUPPLIER")}: </Text>
                                <Text fontSize={'13px'} fontWeight={600} color={'brand.900'}>{item?.supplierName}</Text>
                            </Flex>
                            <Flex>
                                <Text color="secondaryGray.500" fontSize={'13px'} mr={1}>{t("LOCATION")}: </Text>
                                <Text fontSize={'13px'} fontWeight={600} color={'brand.900'}>{_DIAMOND_LOCATION_OPTIONS.find(l => l.id == item?.locationId)?.label}</Text>
                            </Flex>
                        </Flex>
                        <Flex>
                            {
                                _USER_DATA?.user?.roleId != Role._JEWELLER &&
                                <Flex flexDir={'column'}>
                                    <Text fontWeight={700} fontSize={'16px'} mb={"15px"}>{t("SUPPLIER_PRICE")}</Text>
                                    <Text color='gold.600' mt='3px' mb='5px' fontWeight={700} fontSize={'24px'}>${numberWithCurrencyFormat(item?.price)}</Text>
                                    <Text color="secondaryGray.500" fontWeight={500} fontSize={'13px'}>$/CT {numberWithCurrencyFormat(parseFloat(item?.pricePerCarat))}</Text>
                                </Flex>
                            }
                            {
                                _USER_DATA?.user?.roleId != Role._SUPPLIER &&
                                <>
                                    <Divider orientation='vertical' marginX={5} />
                                    <Flex flexDir={'column'}>
                                        <Text fontWeight={700} fontSize={'16px'} mb={"15px"}>{t("TOTAL_PRICE")}</Text>
                                        <Text color='gold.600' mt='3px' mb='5px' fontWeight={700} fontSize={'24px'}>${numberWithCurrencyFormat(item?.totalPrice)}</Text>
                                        <Text color="secondaryGray.500" fontWeight={500} fontSize={'13px'}>$/CT {numberWithCurrencyFormat(parseFloat(item?.markupPricePerCarat))}</Text>
                                    </Flex>
                                </>
                            }
                        </Flex>
                    </Flex>
                    <Divider orientation='vertical' marginX={5} />
                </Flex>
                <Flex flexDir={'row'} flex={5}>
                    <Flex>
                        <TableContainer className="diamond-detail-container">
                            <Table variant='simple' textAlign={'center'}>
                                <Thead>
                                    <Tr>
                                        <Th colSpan={4}>{t('PARAMETER_DETAILS')}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td colSpan={2}>{t('MEASUREMENT')}</Td>
                                        <Td colSpan={2} fontWeight={'800'}>{item?.measurements}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td colSpan={2}>{t('TABLE')}%</Td>
                                        <Td colSpan={2} fontWeight={'800'}>{item?.table}%</Td>
                                    </Tr>
                                    <Tr>
                                        <Td colSpan={2}>{t('DEPTH')}%</Td>
                                        <Td colSpan={2} fontWeight={'800'}>{item?.depth}%</Td>
                                    </Tr>
                                    <Tr>
                                        <Td colSpan={2}>{t('CROWN')}</Td>
                                        <Td colSpan={2} fontWeight={'800'}>{`${item?.crownHeight}%, ${item?.crownAngle}°`}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td colSpan={2}>{t('PAVILION')}</Td>
                                        <Td colSpan={2} fontWeight={'800'}>{`${item?.pavilionDepth}%, ${item?.pavilionAngle}°`}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td colSpan={2}>{t('GIRDLE')}</Td>
                                        <Td colSpan={2} fontWeight={'800'}>{item?.girdle}</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Flex>
                    <Divider orientation='vertical' marginX={5} />
                    <Flex>
                        <TableContainer className="diamond-detail-container">
                            <Table variant='simple' textAlign={'center'}>
                                <Thead>
                                    <Tr>
                                        <Th colSpan={4}>{t('ADDITIONAL_DETAILS')}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td colSpan={2}>{t('SHADE')}</Td>
                                        <Td colSpan={2} fontWeight={'800'}>{_DIAMOND_TINGE_SHADE_OPTIONS.find(s => s.id == item?.tingeShadeId)?.label}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td colSpan={2}>{t('MILKY')}</Td>
                                        <Td colSpan={2} fontWeight={'800'}>{_DIAMOND_TINGE_MILKY_OPTIONS.find(m => m.id == item?.tingeMilkyId)?.label}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td colSpan={2}>{t('EYE_CLEAN')}</Td>
                                        <Td colSpan={2} fontWeight={'800'}>{_DIAMOND_TINGE_EYE_CLEAN_OPTIONS.find(e => e.id == item?.tingeEyeCleanId)?.label}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td colSpan={2}>{t('KEY_TO_SYMBOL')}</Td>
                                        <Td colSpan={2} fontWeight={'800'}>{item?.keyToSymbol}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td colSpan={2}>{t('ORIGIN')}</Td>
                                        <Td colSpan={2} fontWeight={'800'}>{item?.origin}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td colSpan={2}>{t('BRAND')}</Td>
                                        <Td colSpan={2} fontWeight={'800'}>{item?.brand}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td colSpan={2}>{t('TREATMENT')}</Td>
                                        <Td colSpan={2} fontWeight={'800'}>{_DIAMOND_TREATMENT_OPTIONS.find(l => l.id == item?.treatmentId)?.label}</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    )
});

export default DiamondDetail;