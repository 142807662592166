import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "../../../common/useRouter";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
    Box,
    Step,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
    Stack,
    Text,
    FormControl
} from "@chakra-ui/react";
import { showCustomDialog } from "../../../application/action/app_action";
import Card from "../../../components/card/Card";
import { HSeparator } from "../../../components/separator/Separator";
import { WebUrl } from "../../../routes";
import { stringIsNullOrEmpty } from "../../../common/util";
import Parameter from "./parameter";
import Price from "./price";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _location = useLocation();
    const _PARAMS = _location?.state;
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const { t } = useTranslation();
    const { control, handleSubmit, errors, setValue, watch } = useForm();
    const _STEPPER_PARAMETER = 0;
    const _STEPPER_PRICE = 1;
    const [stepper, setStepper] = useState([
        {
            key: _STEPPER_PARAMETER,
            title: 'DIAMOND_PARAMETER',
            isCompleted: true
        },
        {
            key: _STEPPER_PRICE,
            title: 'PRICE_MARKUP',
            isCompleted: false
        }
    ]);
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: stepper.length,
    });

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (stringIsNullOrEmpty(_PARAMS?.typeId)) {
            _router.navigate(WebUrl._DIAMOND_FEED, true);
        }
    }, [])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function onCancel() {
        _router.navigate(WebUrl._DIAMOND_FEED);
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function onPrevious() {
        try {
            setActiveStep(activeStep - 1);
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const onSave = async (data, e) => {
        try {
            let newStepper = [...stepper];
            let targetIndex = newStepper.findIndex(s => s.key == activeStep);
            newStepper[targetIndex]['isCompleted'] = true;
            newStepper[targetIndex + 1]['isCompleted'] = true;
            setStepper(newStepper);
            setActiveStep(activeStep + 1);
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
        }
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card
                justifyContent='center'
                align='center'
                direction='column'
                w='100%'
                mb='0px'>
                <Stepper index={activeStep} colorScheme={'brand'} size={'md'} overflow={'auto'}>
                    {stepper.map((step, index) => (
                        <Step key={index} onClick={() => {
                            if (stepper[index]['isCompleted']) {
                                setActiveStep(index);
                            }
                        }}>
                            <Stack align={'center'}>
                                <StepIndicator>
                                    <StepStatus
                                        complete={<StepNumber />}
                                        incomplete={<StepNumber />}
                                        active={<StepNumber />}
                                    />
                                </StepIndicator>
                                <Box flexShrink='0'>
                                    <StepTitle>{t(step.title)}</StepTitle>
                                </Box>
                            </Stack>
                            <StepSeparator style={{ minWidth: '50px' }} />
                        </Step>
                    ))}
                </Stepper>
                <HSeparator my='20px' />
                <Text fontSize='2xl' fontWeight={'700'} mb={5}>{`${t(_PARAMS?.typeLabel)} ${t(_PARAMS?.fancyColorLabel)}`}</Text>
                <FormControl>
                    {
                        activeStep == _STEPPER_PARAMETER &&
                        <Parameter
                            onSave={onSave}
                            onCancel={onCancel}
                            typeId={_PARAMS?.typeId}
                            isFancyColor={_PARAMS?.isFancyColor} />
                    }
                    {
                        activeStep == _STEPPER_PRICE &&
                        <Price
                            onCancel={onCancel}
                            onPrevious={onPrevious}
                            typeId={_PARAMS?.typeId}
                            isFancyColor={_PARAMS?.isFancyColor} />
                    }
                </FormControl>
            </Card>
        </Box>
    );
}
