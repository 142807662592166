import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
    Flex,
    Text,
    Button,
    IconButton,
    Spacer
} from "@chakra-ui/react";
import { HSeparator } from "../../../components/separator/Separator";
import { showCustomDialog } from "../../../application/action/app_action";
import {
    RegexPattern,
    ApiKey,
    MarkupPriceType,
    _MARKUP_PRICE_OPTIONS,
    _MARKUP_STYLE_OPTIONS
} from "../../../common/constant";
import CustomInput from "../../../components/custom-input";
import CustomSelect from "../../../components/custom-select";
import ApiEngine from "../../../common/api-engine";
import Routes from "../../../common/api_routes";
import { setIdle, setBusy } from "../../../application/action/app_action";
import { FaTrash } from "react-icons/fa6";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ onCancel, onPrevious, typeId, isFancyColor }) => {
    var _dispatch = useDispatch();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const { t } = useTranslation();
    const { control, handleSubmit, errors, setValue, watch } = useForm();
    const [originalMarkup, setOriginalMarkup] = useState();
    const [currentMarkup, setCurrentMarkup] = useState([]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        (async () => {
            try {
                var responseJson = await ApiEngine.get(`${Routes._INVENTORY_MARKUP_RULES}/type/${typeId}/is-fancy/${isFancyColor}`);

                if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                    throw responseJson[ApiKey._API_MESSAGE_KEY];
                }

                setValue('markUpWithCarat', responseJson[ApiKey._API_DATA_KEY]['markUpWithCarat'] ? MarkupPriceType._BY_CARAT : MarkupPriceType._BY_PRICE);
                setOriginalMarkup(responseJson[ApiKey._API_DATA_KEY]);
                setCurrentMarkup(responseJson[ApiKey._API_DATA_KEY]['diamondMarkupRulePrices']);
            }
            catch (err) {
                _dispatch(
                    showCustomDialog({
                        success: false,
                        content: err,
                    })
                );
            }
        })();
    }, [])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    function addNewMarkup() {
        let newMarkup = [...currentMarkup];
        newMarkup.push({
            minRange: 0,
            maxRange: '',
            markupWithPercentage: '',
            amount: ''
        });
        setCurrentMarkup(newMarkup);
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    function updateFieldValue(index, field, value) {
        let newRules = [...currentMarkup];
        newRules[index][field] = value;
        setCurrentMarkup(newRules);
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    function deleteField(idx) {
        let newRules = [...currentMarkup];
        newRules = newRules.filter((_, index) => index !== idx);
        setCurrentMarkup(newRules);
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const onSubmit = async (data, e) => {
        try {
            _dispatch(setBusy());
            let markupParams = {
                typeId: typeId,
                isFancyColor: isFancyColor,
                prices: JSON.stringify(currentMarkup)
            };
            let markUpWithCaratValue = watch('markUpWithCarat') == MarkupPriceType._BY_CARAT;

            if (markUpWithCaratValue != originalMarkup?.markUpWithCarat) {
                let rulesParams = {
                    typeId: typeId,
                    isFancyColor: isFancyColor,
                    markUpWithCarat: markUpWithCaratValue
                };
                var responseJsonRules = await ApiEngine.put(Routes._INVENTORY_MARKUP_RULES, rulesParams);

                if (!responseJsonRules[ApiKey._API_SUCCESS_KEY]) {
                    throw responseJsonRules[ApiKey._API_MESSAGE_KEY];
                }
            }

            var responseJsonMarkup = await ApiEngine.put(`${Routes._INVENTORY_MARKUP_RULES}/price`, markupParams);

            if (!responseJsonMarkup[ApiKey._API_SUCCESS_KEY]) {
                throw responseJsonMarkup[ApiKey._API_MESSAGE_KEY];
            }

            onCancel();
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
        }
        finally {
            _dispatch(setIdle());
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Flex alignItems={'center'}>
                <Flex flexDirection={'column'} mb={'15px'}>
                    <Text textAlign={'left'} width={'150px'} marginRight={'10px'} fontWeight={'bold'}>{t("MARKUP_BY")}</Text>
                </Flex>
                <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'center'} width={{ base: '100%', md: '30%', xl: '30%' }} >
                    <Controller
                        control={control}
                        name="markUpWithCarat"
                        defaultValue={''}
                        render={({ onChange, value, name }) => (
                            <CustomSelect
                                id={name}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    if (currentMarkup.length > 0) {
                                        _dispatch(showCustomDialog({
                                            title: t('ARE_YOU_SURE_YOU'),
                                            content: t('IF_YOU_CHANGE_CURRENT_MARKUP_OPTION'),
                                            onCancel: () => { },
                                            onConfirm: () => {
                                                onChange(value);
                                                setCurrentMarkup([]);
                                            }
                                        }));
                                    }
                                    else {
                                        onChange(value);
                                        setCurrentMarkup([]);
                                    }
                                }}
                                value={value}
                                errors={errors}
                                options={_MARKUP_PRICE_OPTIONS}
                                style={{ width: '100%' }}
                            />
                        )}
                    />
                </Flex>
            </Flex>
            <HSeparator mb='25px' />
            {
                currentMarkup.map((item, index) => {
                    return (
                        <>
                            <Flex alignItems={'center'}>
                                <Flex flexDirection={'column'} mb={'15px'}>
                                    <Text textAlign={'left'} width={'150px'} marginRight={'10px'} fontWeight={'bold'}>{t(watch('markUpWithCarat') == MarkupPriceType._BY_PRICE ? "PRICE_RANGE" : "CARAT_RANGE")}</Text>
                                </Flex>
                                <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'center'} className="option-select" flexWrap={'wrap'} gap={5}>
                                    <Controller
                                        control={control}
                                        name={`minRange_${index}`}
                                        defaultValue={item.minRange}
                                        render={({ onChange, value, name }) => (
                                            <CustomInput
                                                inputType={'decimal'}
                                                id={name}
                                                onChange={(value) => {
                                                    onChange(value);
                                                    updateFieldValue(index, 'minRange', value.target.value);
                                                }}
                                                value={value}
                                                placeHolder={"FROM"}
                                                errors={errors}
                                            />
                                        )}
                                        rules={{
                                            required: true,
                                            message: "REQUIRED",
                                            pattern: RegexPattern._TWO_DECIMAL_VALIDATION
                                        }}
                                    />
                                    <Controller
                                        control={control}
                                        name={`maxRange_${index}`}
                                        defaultValue={item.maxRange}
                                        render={({ onChange, value, name }) => (
                                            <CustomInput
                                                inputType={'decimal'}
                                                id={name}
                                                onChange={(value) => {
                                                    onChange(value);
                                                    updateFieldValue(index, 'maxRange', value.target.value);
                                                }}
                                                value={value}
                                                placeHolder={"TO"}
                                                errors={errors}
                                            />
                                        )}
                                        rules={{
                                            required: true,
                                            message: "REQUIRED",
                                            pattern: RegexPattern._TWO_DECIMAL_VALIDATION
                                        }}
                                    />
                                </Flex>
                            </Flex>
                            <Flex alignItems={'center'}>
                                <Flex flexDirection={'column'} mb={'15px'}>
                                    <Text textAlign={'left'} width={'150px'} marginRight={'10px'} fontWeight={'bold'}>{t("MARKUP_STYLE")}</Text>
                                </Flex>
                                <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'center'} width={{ base: '100%', md: '30%', xl: '30%' }}>
                                    <Controller
                                        control={control}
                                        name={`markupWithPercentage_${index}`}
                                        defaultValue={item.markupWithPercentage.toString()}
                                        render={({ onChange, value, name }) => (
                                            <CustomSelect
                                                id={name}
                                                onChange={(value) => {
                                                    onChange(value);
                                                    updateFieldValue(index, 'markupWithPercentage', value.target.value);
                                                }}
                                                value={value}
                                                errors={errors}
                                                options={_MARKUP_STYLE_OPTIONS}
                                                showEmptyOption
                                                style={{ width: '100%' }}
                                            />
                                        )}
                                        rules={{
                                            required: true,
                                            message: "REQUIRED"
                                        }}
                                    />
                                </Flex>
                            </Flex>
                            <Flex alignItems={'center'}>
                                <Flex flexDirection={'column'} mb={'15px'}>
                                    <Text textAlign={'left'} width={'150px'} marginRight={'10px'} fontWeight={'bold'}>{t("MARKUP_AMOUNT")}</Text>
                                </Flex>
                                <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                                    <Controller
                                        control={control}
                                        name={`amount_${index}`}
                                        defaultValue={item.amount}
                                        render={({ onChange, value, name }) => (
                                            <CustomInput
                                                inputType={'decimal'}
                                                id={name}
                                                onChange={(value) => {
                                                    onChange(value);
                                                    updateFieldValue(index, 'amount', value.target.value);
                                                }}
                                                value={value}
                                                placeHolder={"AMOUNT"}
                                                errors={errors}
                                            />
                                        )}
                                        rules={{
                                            required: true,
                                            message: "REQUIRED",
                                            pattern: RegexPattern._TWO_DECIMAL_VALIDATION
                                        }}
                                    />
                                </Flex>
                                <Spacer />
                                <Flex alignItems={'center'}>
                                    <IconButton
                                        mb={'20px'}
                                        colorScheme={'red'}
                                        icon={<FaTrash />}
                                        onClick={() => {
                                            _dispatch(showCustomDialog({
                                                title: t('ARE_YOU_SURE_YOU'),
                                                content: t('ARE_YOU_SURE_YOU_WANT_TO',
                                                    { action: t('DELETE'), user: `${t(watch('markUpWithCarat') == MarkupPriceType._BY_PRICE ? "PRICE_RANGE" : "CARAT_RANGE")}: [${item.minRange} - ${item.maxRange}] / ${t('MARKUP_STYLE')}: [${t(item.markupWithPercentage ? "PERCENTAGE" : "ABSOLUTE")}] / ${t('MARKUP_AMOUNT')}: [${item.amount}]` }),
                                                onCancel: () => { },
                                                onConfirm: () => {
                                                    deleteField(index);
                                                }
                                            }));
                                        }} />
                                </Flex>
                            </Flex>
                            <HSeparator mb='25px' />
                        </>
                    )
                })
            }
            <Flex justifyContent={'space-between'}>
                <Button
                    fontSize="md"
                    variant="darkBrand"
                    fontWeight="500"
                    mb="24px"
                    type="button"
                    margin={'5px'}
                    onClick={() => {
                        onPrevious();
                    }}
                >
                    {t('PREVIOUS')}
                </Button>
                <Button
                    fontSize="md"
                    variant="outline"
                    colorScheme="green"
                    fontWeight="500"
                    mb="24px"
                    type="button"
                    margin={'5px'}
                    onClick={() => {
                        addNewMarkup();
                    }}
                >
                    {t('ADD_MARKUP')}
                </Button>
                <Button
                    fontSize="md"
                    variant="brand"
                    fontWeight="500"
                    mb="24px"
                    type="submit"
                    margin={'5px'}
                >
                    {t('SAVE')}
                </Button>
            </Flex>
        </form>
    )
}
