import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Flex,
    Button,
    FormControl,
    Text,
    Stack,
    SimpleGrid,
    FormLabel,
    HStack,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    IconButton
} from "@chakra-ui/react";
import { showCustomDialog } from "../../../../application/action/app_action";
import Card from "../../../../components/card/Card";
import { HSeparator } from "../../../../components/separator/Separator";
import { ApiKey, RegexPattern } from "../../../../common/constant";
import ApiEngine from "../../../../common/api-engine";
import Routes from "../../../../common/api_routes";
import CustomInput from "../../../../components/custom-input";
import CustomPhoneInput from "../../../../components/custom-phone-input";
import { stringIsNullOrEmpty } from "../../../../common/util";
import { MdDeleteOutline } from "react-icons/md";
import { checkIsLoggedIn } from "../../../../application/action/auth_action";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ onSave, onCancel, defaultValues }) => {
    var _dispatch = useDispatch();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const { t } = useTranslation();
    const { control, handleSubmit, errors, setValue, watch } = useForm();
    const [companyRef, setCompanyRef] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [numberToRemove, setNumberToRemove] = useState([]);
    const _MAX_REFERENCE_COUNT = 2;
    const _DEFAULT_FIELD = {
        'numbering': 1,
        'companyName': '',
        'personName': '',
        'designation': '',
        'emailId': '',
        'contactNumber': ''
    };
    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        let reference = [..._USER_DATA?.user?.companyReferences];

        if (reference.length == 0) {
            reference.push({ ..._DEFAULT_FIELD });
        }

        setCompanyRef(reference);
    }, []);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function deleteReference(numbering) {
        if (companyRef.length == _MAX_REFERENCE_COUNT) {
            let reference = companyRef.filter(r => r.numbering != numbering);
            setCompanyRef([...reference]);
            setTabIndex(0);
            setNumberToRemove([...numberToRemove, numbering]);
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function updateRef(index, field, value) {
        let newRef = [...companyRef];
        newRef[index][field] = value;

        setCompanyRef([...newRef]);
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const onSubmit = async (data, e) => {
        try {
            let removeIds = "";

            if (numberToRemove.length > 0) {
                let oriData = _USER_DATA?.user?.companyReferences?.map(item => item.numbering);
                removeIds = numberToRemove.filter(number => oriData.includes(number)).join(',');
            }

            let params = {
                references: JSON.stringify([...companyRef]),
                removeNumbers: removeIds
            };
            var responseJson = await ApiEngine.post(Routes._SUPPLIER_COMPANY_REFERENCE, params)

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            _dispatch(
                showCustomDialog({
                    success: true,
                    content: "THANK_YOU_FOR_COMPLETING_THE_COMPANY_PROFILE",
                    onConfirm: () => {
                        _dispatch(checkIsLoggedIn());
                        onCancel();
                    }
                })
            );
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Tabs variant='enclosed' colorScheme={'brand'} index={tabIndex} onChange={(index) => setTabIndex(index)}>
                <TabList>
                    {
                        companyRef.map((item, index) => {
                            return (
                                <Tab key={index} fontWeight={'700'}>
                                    {`${t('PERSON_IN_CHARGE')} ${index + 1}`}
                                    <IconButton
                                        ml={5}
                                        isRound={true}
                                        variant='solid'
                                        colorScheme='red'
                                        size={'sm'}
                                        icon={<MdDeleteOutline />}
                                        onClick={() => { deleteReference(item.numbering) }} />
                                </Tab>
                            )
                        })
                    }
                </TabList>
                <TabPanels>
                    {
                        companyRef.map((item, index) => {
                            return (
                                <TabPanel p={4} key={index}>
                                    <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                        <FormLabel
                                            display={"flex"}
                                            fontSize={"md"}
                                            fontWeight={"500"}
                                            alignItems={'center'}
                                            mb={'20px'}
                                        >
                                            {t('COMPANY_NAME')}
                                        </FormLabel>
                                        <Controller
                                            control={control}
                                            name={`companynName_${item.numbering}`}
                                            defaultValue={_USER_DATA?.user?.supplierProfile?.companyName}
                                            render={({ onChange, value, name }) => (
                                                <CustomInput
                                                    isDisabled
                                                    id={name}
                                                    onChange={(value) => {
                                                        onChange(value);
                                                        updateRef(index, 'companyName', value.target.value);
                                                    }}
                                                    value={value}
                                                    placeHolder={"ENTER_COMPANY_NAME"}
                                                    errors={errors}
                                                />
                                            )}
                                            rules={{
                                                required: true,
                                                message: "REQUIRED",
                                            }}
                                        />
                                    </SimpleGrid>
                                    <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                        <FormLabel
                                            display={"flex"}
                                            fontSize={"md"}
                                            fontWeight={"500"}
                                            alignItems={'center'}
                                            mb={'20px'}
                                        >
                                            {t('PERSON_NAME')}
                                        </FormLabel>
                                        <Controller
                                            control={control}
                                            name={`personName_${item.numbering}`}
                                            defaultValue={item.personName}
                                            render={({ onChange, value, name }) => (
                                                <CustomInput
                                                    id={name}
                                                    onChange={(value) => {
                                                        onChange(value);
                                                        updateRef(index, 'personName', value.target.value);
                                                    }}
                                                    value={item.personName}
                                                    placeHolder={"ENTER_PERSON_NAME"}
                                                    errors={errors}
                                                />
                                            )}
                                            rules={{
                                                required: true,
                                                message: "REQUIRED",
                                            }}
                                        />
                                    </SimpleGrid>
                                    <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                        <FormLabel
                                            display={"flex"}
                                            fontSize={"md"}
                                            fontWeight={"500"}
                                            alignItems={'center'}
                                            mb={'20px'}
                                        >
                                            {t('DESIGNATION')}
                                        </FormLabel>
                                        <Controller
                                            control={control}
                                            name={`designation_${item.numbering}`}
                                            defaultValue={item.designation}
                                            render={({ onChange, value, name }) => (
                                                <CustomInput
                                                    id={name}
                                                    onChange={(value) => {
                                                        onChange(value);
                                                        updateRef(index, 'designation', value.target.value);
                                                    }}
                                                    value={item.designation}
                                                    placeHolder={"ENTER_DESIGNATION"}
                                                    errors={errors}
                                                />
                                            )}
                                            rules={{
                                                required: true,
                                                message: "REQUIRED",
                                            }}
                                        />
                                    </SimpleGrid>
                                    <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                        <FormLabel
                                            display={"flex"}
                                            fontSize={"md"}
                                            fontWeight={"500"}
                                            alignItems={'center'}
                                            mb={'20px'}
                                        >
                                            {t('EMAIL')}
                                        </FormLabel>
                                        <Controller
                                            control={control}
                                            name={`emailId_${item.numbering}`}
                                            defaultValue={item.emailId}
                                            render={({ onChange, value, name }) => (
                                                <CustomInput
                                                    id={name}
                                                    onChange={(value) => {
                                                        onChange(value);
                                                        updateRef(index, 'emailId', value.target.value);
                                                    }}
                                                    value={item.emailId}
                                                    placeHolder={"ENTER_EMAIL"}
                                                    errors={errors}
                                                    patternErrorTxt={'INVALID_EMAIL'}
                                                />
                                            )}
                                            rules={{
                                                required: true,
                                                message: "REQUIRED",
                                                pattern: RegexPattern._EMAIL_VALIDATION
                                            }}
                                        />
                                    </SimpleGrid>
                                    <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                                        <FormLabel
                                            display={"flex"}
                                            fontSize={"md"}
                                            fontWeight={"500"}
                                            alignItems={'center'}
                                            mb={'20px'}
                                        >
                                            {t('CONTACT_NUMBER')}
                                        </FormLabel>
                                        <Controller
                                            control={control}
                                            name={`contactNumber_${item.numbering}}`}
                                            defaultValue={item.contactNumber}
                                            render={({ onChange, value, name }) => (
                                                <CustomPhoneInput
                                                    id={name}
                                                    onChange={(value) => {
                                                        onChange(value);
                                                        updateRef(index, 'contactNumber', value);
                                                    }}
                                                    value={item.contactNumber}
                                                    errors={errors} />
                                            )}
                                            rules={{
                                                required: "REQUIRED"
                                            }}
                                        />
                                    </SimpleGrid>
                                </TabPanel>
                            )
                        })
                    }
                </TabPanels>
            </Tabs>
            <Stack align={'flex-end'} mb={5}>
                <Button
                    isDisabled={companyRef.length == _MAX_REFERENCE_COUNT}
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="25%"
                    h="50"
                    onClick={() => {
                        setCompanyRef([...companyRef, { ..._DEFAULT_FIELD, numbering: companyRef[0]['numbering'] + 1 }]);
                        setTabIndex(companyRef.length);
                    }}>
                    {t('ADD_REFERENCE')}
                </Button>
            </Stack>
            <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="50%"
                h="50"
                type="submit">
                {t('COMPLETE')}
            </Button>
        </form>
    );
}
