import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
    Flex,
    Text,
    Button
} from "@chakra-ui/react";
import CustomInput from "../../../components/custom-input";
import { HSeparator } from "../../../components/separator/Separator";
import CustomOptionSelect from '../../../components/custom-option-select';
import { showCustomDialog } from "../../../application/action/app_action";
import {
    RegexPattern,
    _DIAMOND_SHAPE_OPTIONS,
    _DIAMOND_COLOR_OPTIONS,
    _DIAMOND_FANCY_COLOR_OPTIONS,
    _DIAMOND_FANCY_COLOR_INTENSITY_OPTIONS,
    _DIAMOND_FANCY_COLOR_OVERTONE_OPTIONS,
    _DIAMOND_CLARITY_OPTIONS,
    _DIAMOND_LAB_OPTIONS,
    _DIAMOND_FINISH_LEVEL_OPTIONS,
    _DIAMOND_FLUORESCENSE_OPTIONS,
    _DIAMOND_TINGE_SHADE_OPTIONS,
    _DIAMOND_TINGE_MILKY_OPTIONS,
    _DIAMOND_TINGE_EYE_CLEAN_OPTIONS,
    DiamondClarity,
    DiamondLab,
    DiamondFinishLevel,
    DiamondFluorescence,
    DiamondShape,
    DiamondTingeShade,
    DiamondTingeMilky,
    DiamondTingeEyeClean,
    DiamondColor,
    DiamondFancyColor,
    DiamondFancyColorIntensity,
    DiamondFancyColorOvertone,
    ApiKey
} from "../../../common/constant";
import ApiEngine from "../../../common/api-engine";
import Routes from "../../../common/api_routes";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ onSave, onCancel, typeId, isFancyColor }) => {
    var _dispatch = useDispatch();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const { t } = useTranslation();
    const { control, handleSubmit, errors, setValue, watch } = useForm();
    const shapeMapping = {
        shapeRound: DiamondShape._ROUND,
        shapePrincess: DiamondShape._PRINCESS,
        shapeOval: DiamondShape._OVAL,
        shapeHeart: DiamondShape._HEART,
        shapeCushion: DiamondShape._CUSHION,
        shapeEmerald: DiamondShape._EMERALD,
        shapeMarquise: DiamondShape._MARQUISE,
        shapeCushionModified: DiamondShape._CUSHION_MODIFIED,
        shapePear: DiamondShape._PEAR,
        shapeAsscher: DiamondShape._ASSCHER,
        shapeRadiant: DiamondShape._RADIANT,
        shapeSquareRadiant: DiamondShape._SQUARE_RADIANT,
        shapeTriangle: DiamondShape._TRIANGLE,
        shapeTrilliant: DiamondShape._TRILLIANT,
        shapeOther: DiamondShape._OTHER
    };
    const clarityMapping = {
        clarityFL: DiamondClarity._FL,
        clarityIF: DiamondClarity._IF,
        clarityVVS1: DiamondClarity._VVS1,
        clarityVVS2: DiamondClarity._VVS2,
        clarityVS1: DiamondClarity._VS1,
        clarityVS2: DiamondClarity._VS2,
        claritySI1: DiamondClarity._SI1,
        claritySI2: DiamondClarity._SI2,
        claritySI3: DiamondClarity._SI3,
        clarityI1: DiamondClarity._I1,
        clarityI2: DiamondClarity._I2,
        clarityI3: DiamondClarity._I3
    };
    const labMapping = {
        labGIA: DiamondLab._GIA,
        labIGI: DiamondLab._IGI,
        labHRD: DiamondLab._HRD,
        labGCAL: DiamondLab._GCAL,
        labAGS: DiamondLab._AGS,
        labOTHER: DiamondLab._OTHER
    };
    const cutMapping = {
        cutIdeal: DiamondFinishLevel._ID,
        cutExcellent: DiamondFinishLevel._EX,
        cutVeryGood: DiamondFinishLevel._VG,
        cutGood: DiamondFinishLevel._GD,
        cutFair: DiamondFinishLevel._FR,
        cutPoor: DiamondFinishLevel._PR
    };
    const polishMapping = {
        polishIdeal: DiamondFinishLevel._ID,
        polishExcellent: DiamondFinishLevel._EX,
        polishVeryGood: DiamondFinishLevel._VG,
        polishGood: DiamondFinishLevel._GD,
        polishFair: DiamondFinishLevel._FR,
        polishPoor: DiamondFinishLevel._PR
    };
    const symmetryMapping = {
        symmetryIdeal: DiamondFinishLevel._ID,
        symmetryExcellent: DiamondFinishLevel._EX,
        symmetryVeryGood: DiamondFinishLevel._VG,
        symmetryGood: DiamondFinishLevel._GD,
        symmetryFair: DiamondFinishLevel._FR,
        symmetryPoor: DiamondFinishLevel._PR
    };
    const fluorescenceMapping = {
        fluorescenceNone: DiamondFluorescence._NONE,
        fluorescenceFaint: DiamondFluorescence._FAINT,
        fluorescenceVerySlight: DiamondFluorescence._VERY_SLIGHT,
        fluorescenceSlight: DiamondFluorescence._SLIGHT,
        fluorescenceMedium: DiamondFluorescence._MEDIUM,
        fluorescenceStrong: DiamondFluorescence._STRONG,
        fluorescenceVeryStrong: DiamondFluorescence._VERY_STRONG
    };
    const tingeShadeMapping = {
        tingeShadeNone: DiamondTingeShade._NONE,
        tingeShadeLightBrown: DiamondTingeShade._LIGHT_BROWN,
        tingeShadeBrown: DiamondTingeShade._BROWN,
        tingeShadeLightGreen: DiamondTingeShade._LIGHT_GREEN,
        tingeShadeGreen: DiamondTingeShade._GREEN,
        tingeShadeGrey: DiamondTingeShade._GREY,
        tingeShadeBlack: DiamondTingeShade._BLACK,
        tingeShadePink: DiamondTingeShade._PINK,
        tingeShadeBlue: DiamondTingeShade._BLUE,
        tingeShadeMixTinge: DiamondTingeShade._MIX_TINGE,
        tingeShadeUnknown: DiamondTingeShade._UNKNOWN
    };
    const milkyTingeMapping = {
        tingleMilkyNoMilky: DiamondTingeMilky._NO_MILKY,
        tingleMilkyLightMilky: DiamondTingeMilky._LIGHT_MILKY,
        tingleMilkyYes: DiamondTingeMilky._MILKY,
        tingleMilkyUnknown: DiamondTingeMilky._UNKNOWN
    };
    const eyeCleanMapping = {
        tingleEyeCleanYes: DiamondTingeEyeClean._EYE_CLEAN,
        tingleEyeCleanNo: DiamondTingeEyeClean._NO,
        tingleEyeCleanOther: DiamondTingeEyeClean._OTHER
    };
    const colorMapping = {
        colorD: DiamondColor._D,
        colorE: DiamondColor._E,
        colorF: DiamondColor._F,
        colorG: DiamondColor._G,
        colorH: DiamondColor._H,
        colorI: DiamondColor._I,
        colorJ: DiamondColor._J,
        colorK: DiamondColor._K,
        colorL: DiamondColor._L,
        colorM: DiamondColor._M,
        colorN: DiamondColor._N,
        colorOP: DiamondColor._OP,
        colorOR: DiamondColor._QR, // Assuming _QR should map to colorOR based on the class definition
        colorST: DiamondColor._ST,
        colorUV: DiamondColor._UV,
        colorWX: DiamondColor._WX,
        colorYZ: DiamondColor._YZ
    };
    const fancyColorMapping = {
        fancyBlack: DiamondFancyColor._BLACK,
        fancyBlue: DiamondFancyColor._BLUE,
        fancyBrown: DiamondFancyColor._BROWN,
        fancyChameleon: DiamondFancyColor._CHAMELEON,
        fancyCognac: DiamondFancyColor._COGNAC,
        fancyGray: DiamondFancyColor._GRAY,
        fancyGreen: DiamondFancyColor._GREEN,
        fancyOrange: DiamondFancyColor._ORANGE,
        fancyPink: DiamondFancyColor._PINK,
        fancyPurple: DiamondFancyColor._PURPLE,
        fancyRed: DiamondFancyColor._RED,
        fancyViolet: DiamondFancyColor._VIOLET,
        fancyYellow: DiamondFancyColor._YELLOW,
        fancyWhite: DiamondFancyColor._WHITE,
        fancyOther: DiamondFancyColor._OTHER
    };
    const fancyIntensityMapping = {
        fancyIntensityFaint: DiamondFancyColorIntensity._FAINT,
        fancyIntensityVeryLight: DiamondFancyColorIntensity._VERY_LIGHT,
        fancyIntensityLight: DiamondFancyColorIntensity._LIGHT,
        fancyIntensityFancyLight: DiamondFancyColorIntensity._FANCY_LIGHT,
        fancyIntensityFancyDark: DiamondFancyColorIntensity._FANCY_DARK,
        fancyIntensityFancyIntense: DiamondFancyColorIntensity._FANCY_INTENSE,
        fancyIntensityFancyVivid: DiamondFancyColorIntensity._FANCY_VIVID,
        fancyIntensityFancyDeep: DiamondFancyColorIntensity._FANCY_DEEP
    };
    const fancyOvertoneMapping = {
        fancyOvertoneNone: DiamondFancyColorOvertone._NONE,
        fancyOvertoneYellow: DiamondFancyColorOvertone._YELLOW,
        fancyOvertoneYellowish: DiamondFancyColorOvertone._YELLOWISH,
        fancyOvertonePink: DiamondFancyColorOvertone._PINK,
        fancyOvertonePinkish: DiamondFancyColorOvertone._PINKISH,
        fancyOvertoneBlue: DiamondFancyColorOvertone._BLUE,
        fancyOvertoneBluish: DiamondFancyColorOvertone._BLUISH,
        fancyOvertoneRed: DiamondFancyColorOvertone._RED,
        fancyOvertoneReddish: DiamondFancyColorOvertone._REDDISH,
        fancyOvertoneGreen: DiamondFancyColorOvertone._GREEN,
        fancyOvertoneGreenish: DiamondFancyColorOvertone._GREENISH,
        fancyOvertonePurple: DiamondFancyColorOvertone._PURPLE,
        fancyOvertonePurplish: DiamondFancyColorOvertone._PURPLISH,
        fancyOvertoneOrange: DiamondFancyColorOvertone._ORANGE,
        fancyOvertoneOrangey: DiamondFancyColorOvertone._ORANGEY,
        fancyOvertoneViolet: DiamondFancyColorOvertone._VIOLET,
        fancyOvertoneGray: DiamondFancyColorOvertone._GRAY,
        fancyOvertoneGrayish: DiamondFancyColorOvertone._GRAYISH,
        fancyOvertoneBlack: DiamondFancyColorOvertone._BLACK,
        fancyOvertoneBrown: DiamondFancyColorOvertone._BROWN,
        fancyOvertoneBrownish: DiamondFancyColorOvertone._BROWNISH,
        fancyOvertoneChampange: DiamondFancyColorOvertone._CHAMPANGE,
        fancyOvertoneCognac: DiamondFancyColorOvertone._COGNAC,
        fancyOvertoneChameleon: DiamondFancyColorOvertone._CHAMELEON,
        fancyOvertoneVioletish: DiamondFancyColorOvertone._VIOLETISH,
        fancyOvertoneWhite: DiamondFancyColorOvertone._WHITE,
        fancyOvertoneOther: DiamondFancyColorOvertone._OTHER
    };

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        (async () => {
            try {
                var responseJson = await ApiEngine.get(`${Routes._INVENTORY_MARKUP_RULES}/type/${typeId}/is-fancy/${isFancyColor}`);

                if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                    throw responseJson[ApiKey._API_MESSAGE_KEY];
                }

                if (responseJson[ApiKey._API_DATA_KEY]) {
                    let data = responseJson[ApiKey._API_DATA_KEY];
                    setValue('diamondShapeIds', Object.entries(data?.diamondShape).filter(([key, value]) => value === true).map(([key]) => shapeMapping[key]));
                    setValue('diamondClarityIds', Object.entries(data?.diamondClarity).filter(([key, value]) => value === true).map(([key]) => clarityMapping[key]));
                    setValue('diamondLabIds', Object.entries(data?.diamondLab).filter(([key, value]) => value === true).map(([key]) => labMapping[key]));
                    setValue('diamondCutIds', Object.entries(data?.diamondCut).filter(([key, value]) => value === true).map(([key]) => cutMapping[key]));
                    setValue('diamondPolishIds', Object.entries(data?.diamondPolish).filter(([key, value]) => value === true).map(([key]) => polishMapping[key]));
                    setValue('diamondSymmIds', Object.entries(data?.diamondSymmetry).filter(([key, value]) => value === true).map(([key]) => symmetryMapping[key]));
                    setValue('diamondFluoresenceIds', Object.entries(data?.diamondFluorescence).filter(([key, value]) => value === true).map(([key]) => fluorescenceMapping[key]));
                    setValue('diamondShadeIds', Object.entries(data?.diamondShade).filter(([key, value]) => value === true).map(([key]) => tingeShadeMapping[key]));
                    setValue('diamondMilkyIds', Object.entries(data?.diamondMilky).filter(([key, value]) => value === true).map(([key]) => milkyTingeMapping[key]));
                    setValue('diamondEyeCleanIds', Object.entries(data?.diamondEyeClean).filter(([key, value]) => value === true).map(([key]) => eyeCleanMapping[key]));
                    setValue('caratMinRange', data?.caratMinRange);
                    setValue('caratMaxRange', data?.caratMaxRange);
                    setValue('priceMinRange', data?.priceMinRange);
                    setValue('priceMaxRange', data?.priceMaxRange);

                    if (!isFancyColor) {
                        setValue('diamondColorIds', Object.entries(data?.diamondColor).filter(([key, value]) => value === true).map(([key]) => colorMapping[key]));
                    }
                    else {
                        setValue('fancyColorIds', Object.entries(data?.diamondFancyColor).filter(([key, value]) => value === true).map(([key]) => fancyColorMapping[key]));
                        setValue('fancyIntensityIds', Object.entries(data?.diamondFancyIntensity).filter(([key, value]) => value === true).map(([key]) => fancyIntensityMapping[key]));
                        setValue('fancyOvertoneIds', Object.entries(data?.diamondFancyOvertone).filter(([key, value]) => value === true).map(([key]) => fancyOvertoneMapping[key]));
                    }
                }
            }
            catch (err) {
                _dispatch(
                    showCustomDialog({
                        success: false,
                        content: err,
                    })
                );
            }
        })();
    }, [])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const onSubmit = async (data, e) => {
        try {
            let params = {
                //"isActive": true,
                //"mediaAssetSetting": 2,
                //"markUpWithCarat": true,
                "typeId": typeId,
                "isFancyColor": isFancyColor,
                "caratMinRange": parseFloat(data.caratMinRange),
                "caratMaxRange": parseFloat(data.caratMaxRange),
                "priceMinRange": parseFloat(data.priceMinRange),
                "priceMaxRange": parseFloat(data.priceMaxRange),
                "clarityFL": data?.diamondClarityIds?.includes(DiamondClarity._FL) ?? false,
                "clarityIF": data?.diamondClarityIds?.includes(DiamondClarity._IF) ?? false,
                "clarityVVS1": data?.diamondClarityIds?.includes(DiamondClarity._VVS1) ?? false,
                "clarityVVS2": data?.diamondClarityIds?.includes(DiamondClarity._VVS2) ?? false,
                "clarityVS1": data?.diamondClarityIds?.includes(DiamondClarity._VS1) ?? false,
                "clarityVS2": data?.diamondClarityIds?.includes(DiamondClarity._VS2) ?? false,
                "claritySI1": data?.diamondClarityIds?.includes(DiamondClarity._SI1) ?? false,
                "claritySI2": data?.diamondClarityIds?.includes(DiamondClarity._SI2) ?? false,
                "claritySI3": data?.diamondClarityIds?.includes(DiamondClarity._SI3) ?? false,
                "clarityI1": data?.diamondClarityIds?.includes(DiamondClarity._I1) ?? false,
                "clarityI2": data?.diamondClarityIds?.includes(DiamondClarity._I2) ?? false,
                "clarityI3": data?.diamondClarityIds?.includes(DiamondClarity._I3) ?? false,
                "labGIA": data?.diamondLabIds?.includes(DiamondLab._GIA) ?? false,
                "labIGI": data?.diamondLabIds?.includes(DiamondLab._IGI) ?? false,
                "labHRD": data?.diamondLabIds?.includes(DiamondLab._HRD) ?? false,
                "labGCAL": data?.diamondLabIds?.includes(DiamondLab._GCAL) ?? false,
                "labAGS": data?.diamondLabIds?.includes(DiamondLab._AGS) ?? false,
                "labOTHER": data?.diamondLabIds?.includes(DiamondLab._OTHER) ?? false,
                "cutIdeal": data?.diamondCutIds?.includes(DiamondFinishLevel._ID) ?? false,
                "cutExcellent": data?.diamondCutIds?.includes(DiamondFinishLevel._EX) ?? false,
                "cutVeryGood": data?.diamondCutIds?.includes(DiamondFinishLevel._VG) ?? false,
                "cutGood": data?.diamondCutIds?.includes(DiamondFinishLevel._GD) ?? false,
                "cutFair": data?.diamondCutIds?.includes(DiamondFinishLevel._FR) ?? false,
                "cutPoor": data?.diamondCutIds?.includes(DiamondFinishLevel._PR) ?? false,
                "polishIdeal": data?.diamondPolishIds?.includes(DiamondFinishLevel._ID) ?? false,
                "polishExcellent": data?.diamondPolishIds?.includes(DiamondFinishLevel._EX) ?? false,
                "polishVeryGood": data?.diamondPolishIds?.includes(DiamondFinishLevel._VG) ?? false,
                "polishGood": data?.diamondPolishIds?.includes(DiamondFinishLevel._GD) ?? false,
                "polishFair": data?.diamondPolishIds?.includes(DiamondFinishLevel._FR) ?? false,
                "polishPoor": data?.diamondPolishIds?.includes(DiamondFinishLevel._PR) ?? false,
                "symmetryIdeal": data?.diamondSymmIds?.includes(DiamondFinishLevel._ID) ?? false,
                "symmetryExcellent": data?.diamondSymmIds?.includes(DiamondFinishLevel._EX) ?? false,
                "symmetryVeryGood": data?.diamondSymmIds?.includes(DiamondFinishLevel._VG) ?? false,
                "symmetryGood": data?.diamondSymmIds?.includes(DiamondFinishLevel._GD) ?? false,
                "symmetryFair": data?.diamondSymmIds?.includes(DiamondFinishLevel._FR) ?? false,
                "symmetryPoor": data?.diamondSymmIds?.includes(DiamondFinishLevel._PR) ?? false,
                "fluorescenceNone": data?.diamondFluoresenceIds?.includes(DiamondFluorescence._NONE) ?? false,
                "fluorescenceFaint": data?.diamondFluoresenceIds?.includes(DiamondFluorescence._FAINT) ?? false,
                "fluorescenceVerySlight": data?.diamondFluoresenceIds?.includes(DiamondFluorescence._VERY_SLIGHT) ?? false,
                "fluorescenceSlight": data?.diamondFluoresenceIds?.includes(DiamondFluorescence._SLIGHT) ?? false,
                "fluorescenceMedium": data?.diamondFluoresenceIds?.includes(DiamondFluorescence._MEDIUM) ?? false,
                "fluorescenceStrong": data?.diamondFluoresenceIds?.includes(DiamondFluorescence._STRONG) ?? false,
                "fluorescenceVeryStrong": data?.diamondFluoresenceIds?.includes(DiamondFluorescence._VERY_STRONG) ?? false,
                "shapeRound": data?.diamondShapeIds?.includes(DiamondShape._ROUND) ?? false,
                "shapePrincess": data?.diamondShapeIds?.includes(DiamondShape._PRINCESS) ?? false,
                "shapeOval": data?.diamondShapeIds?.includes(DiamondShape._OVAL) ?? false,
                "shapeHeart": data?.diamondShapeIds?.includes(DiamondShape._HEART) ?? false,
                "shapeCushion": data?.diamondShapeIds?.includes(DiamondShape._CUSHION) ?? false,
                "shapeEmerald": data?.diamondShapeIds?.includes(DiamondShape._EMERALD) ?? false,
                "shapeMarquise": data?.diamondShapeIds?.includes(DiamondShape._MARQUISE) ?? false,
                "shapeCushionModified": data?.diamondShapeIds?.includes(DiamondShape._CUSHION_MODIFIED) ?? false,
                "shapePear": data?.diamondShapeIds?.includes(DiamondShape._PEAR) ?? false,
                "shapeAsscher": data?.diamondShapeIds?.includes(DiamondShape._ASSCHER) ?? false,
                "shapeRadiant": data?.diamondShapeIds?.includes(DiamondShape._RADIANT) ?? false,
                "shapeSquareRadiant": data?.diamondShapeIds?.includes(DiamondShape._SQUARE_RADIANT) ?? false,
                "shapeTriangle": data?.diamondShapeIds?.includes(DiamondShape._TRIANGLE) ?? false,
                "shapeTrilliant": data?.diamondShapeIds?.includes(DiamondShape._TRILLIANT) ?? false,
                "shapeOther": data?.diamondShapeIds?.includes(DiamondShape._OTHER) ?? false,
                "tingeShadeNone": data?.diamondShadeIds?.includes(DiamondTingeShade._NONE) ?? false,
                "tingeShadeLightBrown": data?.diamondShadeIds?.includes(DiamondTingeShade._LIGHT_BROWN) ?? false,
                "tingeShadeBrown": data?.diamondShadeIds?.includes(DiamondTingeShade._BROWN) ?? false,
                "tingeShadeLightGreen": data?.diamondShadeIds?.includes(DiamondTingeShade._LIGHT_GREEN) ?? false,
                "tingeShadeGreen": data?.diamondShadeIds?.includes(DiamondTingeShade._GREEN) ?? false,
                "tingeShadeGrey": data?.diamondShadeIds?.includes(DiamondTingeShade._GREY) ?? false,
                "tingeShadeBlack": data?.diamondShadeIds?.includes(DiamondTingeShade._BLACK) ?? false,
                "tingeShadePink": data?.diamondShadeIds?.includes(DiamondTingeShade._PINK) ?? false,
                "tingeShadeBlue": data?.diamondShadeIds?.includes(DiamondTingeShade._BLUE) ?? false,
                "tingeShadeMixTinge": data?.diamondShadeIds?.includes(DiamondTingeShade._MIX_TINGE) ?? false,
                "tingeShadeUnknown": data?.diamondShadeIds?.includes(DiamondTingeShade._UNKNOWN) ?? false,
                "tingleMilkyNoMilky": data?.diamondMilkyIds?.includes(DiamondTingeMilky._NO_MILKY) ?? false,
                "tingleMilkyLightMilky": data?.diamondMilkyIds?.includes(DiamondTingeMilky._LIGHT_MILKY) ?? false,
                "tingleMilkyYes": data?.diamondMilkyIds?.includes(DiamondTingeMilky._MILKY) ?? false,
                "tingleMilkyUnknown": data?.diamondMilkyIds?.includes(DiamondTingeMilky._UNKNOWN) ?? false,
                "tingleEyeCleanYes": data?.diamondEyeCleanIds?.includes(DiamondTingeEyeClean._EYE_CLEAN) ?? false,
                "tingleEyeCleanNo": data?.diamondEyeCleanIds?.includes(DiamondTingeEyeClean._NO) ?? false,
                "tingleEyeCleanOther": data?.diamondEyeCleanIds?.includes(DiamondTingeEyeClean._OTHER) ?? false
            };

            if (!isFancyColor) {
                params["colorD"] = data?.diamondColorIds?.includes(DiamondColor._D) ?? false;
                params["colorE"] = data?.diamondColorIds?.includes(DiamondColor._E) ?? false;
                params["colorF"] = data?.diamondColorIds?.includes(DiamondColor._F) ?? false;
                params["colorG"] = data?.diamondColorIds?.includes(DiamondColor._G) ?? false;
                params["colorH"] = data?.diamondColorIds?.includes(DiamondColor._H) ?? false;
                params["colorI"] = data?.diamondColorIds?.includes(DiamondColor._I) ?? false;
                params["colorJ"] = data?.diamondColorIds?.includes(DiamondColor._J) ?? false;
                params["colorK"] = data?.diamondColorIds?.includes(DiamondColor._K) ?? false;
                params["colorL"] = data?.diamondColorIds?.includes(DiamondColor._L) ?? false;
                params["colorM"] = data?.diamondColorIds?.includes(DiamondColor._M) ?? false;
                params["colorN"] = data?.diamondColorIds?.includes(DiamondColor._N) ?? false;
                params["colorOP"] = data?.diamondColorIds?.includes(DiamondColor._OP) ?? false;
                params["colorOR"] = data?.diamondColorIds?.includes(DiamondColor._QR) ?? false;
                params["colorST"] = data?.diamondColorIds?.includes(DiamondColor._ST) ?? false;
                params["colorUV"] = data?.diamondColorIds?.includes(DiamondColor._UV) ?? false;
                params["colorWX"] = data?.diamondColorIds?.includes(DiamondColor._WX) ?? false;
                params["colorYZ"] = data?.diamondColorIds?.includes(DiamondColor._YZ) ?? false;
                // params["fancyBlack"] = false;
                // params["fancyBlue"] = false;
                // params["fancyBrown"] = false;
                // params["fancyChameleon"] = false;
                // params["fancyCognac"] = false;
                // params["fancyGray"] = false;
                // params["fancyGreen"] = false;
                // params["fancyOrange"] = false;
                // params["fancyPink"] = false;
                // params["fancyPurple"] = false;
                // params["fancyRed"] = false;
                // params["fancyViolet"] = false;
                // params["fancyYellow"] = false;
                // params["fancyWhite"] = false;
                // params["fancyOther"] = false;
                // params["fancyIntensityFaint"] = false;
                // params["fancyIntensityVeryLight"] = false;
                // params["fancyIntensityLight"] = false;
                // params["fancyIntensityFancyLight"] = false;
                // params["fancyIntensityFancyDark"] = false;
                // params["fancyIntensityFancyIntense"] = false;
                // params["fancyIntensityFancyVivid"] = false;
                // params["fancyIntensityFancyDeep"] = false;
                // params["fancyOvertoneNone"] = false;
                // params["fancyOvertoneYellow"] = false;
                // params["fancyOvertoneYellowish"] = false;
                // params["fancyOvertonePink"] = false;
                // params["fancyOvertonePinkish"] = false;
                // params["fancyOvertoneBlue"] = false;
                // params["fancyOvertoneBluish"] = false;
                // params["fancyOvertoneRed"] = false;
                // params["fancyOvertoneReddish"] = false;
                // params["fancyOvertoneGreen"] = false;
                // params["fancyOvertoneGreenish"] = false;
                // params["fancyOvertonePurple"] = false;
                // params["fancyOvertonePurplish"] = false;
                // params["fancyOvertoneOrange"] = false;
                // params["fancyOvertoneOrangey"] = false;
                // params["fancyOvertoneViolet"] = false;
                // params["fancyOvertoneGray"] = false;
                // params["fancyOvertoneGrayish"] = false;
                // params["fancyOvertoneBlack"] = false;
                // params["fancyOvertoneBrown"] = false;
                // params["fancyOvertoneBrownish"] = false;
                // params["fancyOvertoneChampange"] = false;
                // params["fancyOvertoneCognac"] = false;
                // params["fancyOvertoneChameleon"] = false;
                // params["fancyOvertoneVioletish"] = false;
                // params["fancyOvertoneWhite"] = false;
                // params["fancyOvertoneOther"] = false;
            }
            else {
                // params["colorD"] = false;
                // params["colorE"] = false;
                // params["colorF"] = false;
                // params["colorG"] = false;
                // params["colorH"] = false;
                // params["colorI"] = false;
                // params["colorJ"] = false;
                // params["colorK"] = false;
                // params["colorL"] = false;
                // params["colorM"] = false;
                // params["colorN"] = false;
                // params["colorOP"] = false;
                // params["colorOR"] = false;
                // params["colorST"] = false;
                // params["colorUV"] = false;
                // params["colorWX"] = false;
                // params["colorYZ"] = false;
                params["fancyBlack"] = data?.fancyColorIds?.includes(DiamondFancyColor._BLACK) ?? false;
                params["fancyBlue"] = data?.fancyColorIds?.includes(DiamondFancyColor._BLUE) ?? false;
                params["fancyBrown"] = data?.fancyColorIds?.includes(DiamondFancyColor._BROWN) ?? false;
                params["fancyChameleon"] = data?.fancyColorIds?.includes(DiamondFancyColor._CHAMELEON) ?? false;
                params["fancyCognac"] = data?.fancyColorIds?.includes(DiamondFancyColor._COGNAC) ?? false;
                params["fancyGray"] = data?.fancyColorIds?.includes(DiamondFancyColor._GRAY) ?? false;
                params["fancyGreen"] = data?.fancyColorIds?.includes(DiamondFancyColor._GREEN) ?? false;
                params["fancyOrange"] = data?.fancyColorIds?.includes(DiamondFancyColor._ORANGE) ?? false;
                params["fancyPink"] = data?.fancyColorIds?.includes(DiamondFancyColor._PINK) ?? false;
                params["fancyPurple"] = data?.fancyColorIds?.includes(DiamondFancyColor._PURPLE) ?? false;
                params["fancyRed"] = data?.fancyColorIds?.includes(DiamondFancyColor._RED) ?? false;
                params["fancyViolet"] = data?.fancyColorIds?.includes(DiamondFancyColor._VIOLET) ?? false;
                params["fancyYellow"] = data?.fancyColorIds?.includes(DiamondFancyColor._YELLOW) ?? false;
                params["fancyWhite"] = data?.fancyColorIds?.includes(DiamondFancyColor._WHITE) ?? false;
                params["fancyOther"] = data?.fancyColorIds?.includes(DiamondFancyColor._OTHER) ?? false;
                params["fancyIntensityFaint"] = data?.fancyIntensityIds?.includes(DiamondFancyColorIntensity._FAINT) ?? false;
                params["fancyIntensityVeryLight"] = data?.fancyIntensityIds?.includes(DiamondFancyColorIntensity._VERY_LIGHT) ?? false;
                params["fancyIntensityLight"] = data?.fancyIntensityIds?.includes(DiamondFancyColorIntensity._LIGHT) ?? false;
                params["fancyIntensityFancyLight"] = data?.fancyIntensityIds?.includes(DiamondFancyColorIntensity._FANCY_LIGHT) ?? false;
                params["fancyIntensityFancyDark"] = data?.fancyIntensityIds?.includes(DiamondFancyColorIntensity._FANCY_DARK) ?? false;
                params["fancyIntensityFancyIntense"] = data?.fancyIntensityIds?.includes(DiamondFancyColorIntensity._FANCY_INTENSE) ?? false;
                params["fancyIntensityFancyVivid"] = data?.fancyIntensityIds?.includes(DiamondFancyColorIntensity._FANCY_VIVID) ?? false;
                params["fancyIntensityFancyDeep"] = data?.fancyIntensityIds?.includes(DiamondFancyColorIntensity._FANCY_DEEP) ?? false;
                params["fancyOvertoneNone"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._NONE) ?? false;
                params["fancyOvertoneYellow"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._YELLOW) ?? false;
                params["fancyOvertoneYellowish"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._YELLOWISH) ?? false;
                params["fancyOvertonePink"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._PINK) ?? false;
                params["fancyOvertonePinkish"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._PINKISH) ?? false;
                params["fancyOvertoneBlue"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._BLUE) ?? false;
                params["fancyOvertoneBluish"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._BLUISH) ?? false;
                params["fancyOvertoneRed"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._RED) ?? false;
                params["fancyOvertoneReddish"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._REDDISH) ?? false;
                params["fancyOvertoneGreen"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._GREEN) ?? false;
                params["fancyOvertoneGreenish"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._GREENISH) ?? false;
                params["fancyOvertonePurple"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._PURPLE) ?? false;
                params["fancyOvertonePurplish"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._PURPLISH) ?? false;
                params["fancyOvertoneOrange"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._ORANGE) ?? false;
                params["fancyOvertoneOrangey"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._ORANGEY) ?? false;
                params["fancyOvertoneViolet"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._VIOLET) ?? false;
                params["fancyOvertoneGray"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._GRAY) ?? false;
                params["fancyOvertoneGrayish"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._GRAYISH) ?? false;
                params["fancyOvertoneBlack"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._BLACK) ?? false;
                params["fancyOvertoneBrown"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._BROWN) ?? false;
                params["fancyOvertoneBrownish"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._BROWNISH) ?? false;
                params["fancyOvertoneChampange"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._CHAMPANGE) ?? false;
                params["fancyOvertoneCognac"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._COGNAC) ?? false;
                params["fancyOvertoneChameleon"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._CHAMELEON) ?? false;
                params["fancyOvertoneVioletish"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._VIOLETISH) ?? false;
                params["fancyOvertoneWhite"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._WHITE) ?? false;
                params["fancyOvertoneOther"] = data?.fancyOvertoneIds?.includes(DiamondFancyColorOvertone._OTHER) ?? false;
            }

            var responseJson = await ApiEngine.put(Routes._INVENTORY_MARKUP_RULES, params);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            onSave();
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
                control={control}
                name="diamondShapeIds"
                defaultValue={[]}
                render={({ onChange, value, name }) => (
                    <CustomOptionSelect
                        id={name}
                        btnFontSize={'11px'}
                        width={'75px'}
                        height={'75px'}
                        label={t('SHAPE')}
                        options={_DIAMOND_SHAPE_OPTIONS}
                        onChange={onChange}
                        selectedValue={value}
                        disabledOuterWrap
                    />
                )}
            />
            <HSeparator mb='25px' />
            <Flex alignItems={'center'}>
                <Flex flexDirection={'column'} mb={'15px'}>
                    <Text textAlign={'left'} width={'150px'} marginRight={'10px'} fontWeight={'bold'}>{t("CARAT_RANGE")}</Text>
                </Flex>
                <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'center'} className="option-select" flexWrap={'wrap'} gap={5}>
                    <Controller
                        control={control}
                        name="caratMinRange"
                        defaultValue={""}
                        render={({ onChange, value, name }) => (
                            <CustomInput
                                inputType={'decimal'}
                                id={name}
                                onChange={(value) => onChange(value)}
                                value={value}
                                placeHolder={"FROM"}
                                errors={errors}
                            />
                        )}
                        rules={{
                            required: true,
                            message: "REQUIRED",
                            pattern: RegexPattern._TWO_DECIMAL_VALIDATION
                        }}
                    />
                    <Controller
                        control={control}
                        name="caratMaxRange"
                        defaultValue={""}
                        render={({ onChange, value, name }) => (
                            <CustomInput
                                inputType={'decimal'}
                                id={name}
                                onChange={(value) => onChange(value)}
                                value={value}
                                placeHolder={"TO"}
                                errors={errors}
                            />
                        )}
                        rules={{
                            required: true,
                            message: "REQUIRED",
                            pattern: RegexPattern._TWO_DECIMAL_VALIDATION,
                            validate: value =>
                                value > watch('caratMinRange') || t('TO_VALUE_MUST_GREATER_THAN_FROM_VALUE')
                        }}
                    />
                </Flex>
            </Flex>
            <HSeparator mb='25px' />
            {
                isFancyColor ?
                    <Flex flexDirection={'row'} alignItems={'center'}>
                        <Flex flexDirection={'column'} mb={'15px'}>
                            <Text textAlign={'left'} width={'150px'} marginRight={'10px'} fontWeight={'bold'}>{t("FANCY_COLOR_DIAMOND")}</Text>
                        </Flex>
                        <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'} className="option-select" flexWrap={'wrap'}>
                            <Flex flexDir={'column'} alignItems={'flex-start'}>
                                <Text fontWeight={800}>{t("INTENSITY")}</Text>
                                <Controller
                                    control={control}
                                    name="fancyIntensityIds"
                                    defaultValue={[]}
                                    render={({ onChange, value, name }) => (
                                        <CustomOptionSelect
                                            id={name}
                                            options={_DIAMOND_FANCY_COLOR_INTENSITY_OPTIONS}
                                            onChange={onChange}
                                            selectedValue={value}
                                            disabledOuterWrap
                                        />
                                    )}
                                />
                                <Text fontWeight={800}>{t("SUB_COLOR")}</Text>
                                <Controller
                                    control={control}
                                    name="fancyOvertoneIds"
                                    defaultValue={[]}
                                    render={({ onChange, value, name }) => (
                                        <CustomOptionSelect
                                            id={name}
                                            options={_DIAMOND_FANCY_COLOR_OVERTONE_OPTIONS}
                                            onChange={onChange}
                                            selectedValue={value}
                                            disabledOuterWrap
                                        />
                                    )}
                                />
                                <Text fontWeight={800}>{t("DOMINANT_COLOR")}</Text>
                                <Controller
                                    control={control}
                                    name="fancyColorIds"
                                    defaultValue={[]}
                                    render={({ onChange, value, name }) => (
                                        <CustomOptionSelect
                                            id={name}
                                            options={_DIAMOND_FANCY_COLOR_OPTIONS}
                                            onChange={onChange}
                                            selectedValue={value}
                                            disabledOuterWrap
                                        />
                                    )}
                                />
                            </Flex>

                        </Flex>
                    </Flex>
                    :
                    <Controller
                        control={control}
                        name="diamondColorIds"
                        defaultValue={[]}
                        render={({ onChange, value, name }) => (
                            <CustomOptionSelect
                                id={name}
                                label={t('COLORLESS_DIAMOND')}
                                options={_DIAMOND_COLOR_OPTIONS}
                                onChange={onChange}
                                selectedValue={value}
                                disabledOuterWrap
                            />
                        )}
                    />
            }
            <HSeparator mb='25px' />
            <Controller
                control={control}
                name="diamondClarityIds"
                defaultValue={[]}
                render={({ onChange, value, name }) => (
                    <CustomOptionSelect
                        id={name}
                        label={t('CLARITY')}
                        options={_DIAMOND_CLARITY_OPTIONS}
                        onChange={onChange}
                        selectedValue={value}
                        disabledOuterWrap
                    />
                )}
            />
            <HSeparator mb='25px' />
            <Controller
                control={control}
                name="diamondLabIds"
                defaultValue={[]}
                render={({ onChange, value, name }) => (
                    <CustomOptionSelect
                        id={name}
                        label={t('LAB')}
                        options={_DIAMOND_LAB_OPTIONS}
                        onChange={onChange}
                        selectedValue={value}
                        disabledOuterWrap
                    />
                )}
            />
            <HSeparator mb='25px' />
            <Controller
                control={control}
                name="diamondCutIds"
                defaultValue={[]}
                render={({ onChange, value, name }) => (
                    <CustomOptionSelect
                        id={name}
                        label={t('FINISH')}
                        description={t("CUT")}
                        options={_DIAMOND_FINISH_LEVEL_OPTIONS}
                        onChange={onChange}
                        selectedValue={value}
                        disabledOuterWrap
                    />
                )}
            />
            <HSeparator mb='25px' />
            <Controller
                control={control}
                name="diamondPolishIds"
                defaultValue={[]}
                render={({ onChange, value, name }) => (
                    <CustomOptionSelect
                        id={name}
                        label={t('FINISH')}
                        description={t("POLISH")}
                        options={_DIAMOND_FINISH_LEVEL_OPTIONS}
                        onChange={onChange}
                        selectedValue={value}
                        disabledOuterWrap
                    />
                )}
            />
            <HSeparator mb='25px' />
            <Controller
                control={control}
                name="diamondSymmIds"
                defaultValue={[]}
                render={({ onChange, value, name }) => (
                    <CustomOptionSelect
                        id={name}
                        label={t('FINISH')}
                        description={t("SYMMETRY")}
                        options={_DIAMOND_FINISH_LEVEL_OPTIONS}
                        onChange={onChange}
                        selectedValue={value}
                        disabledOuterWrap
                    />
                )}
            />
            <HSeparator mb='25px' />
            <Controller
                control={control}
                name="diamondFluoresenceIds"
                defaultValue={[]}
                render={({ onChange, value, name }) => (
                    <CustomOptionSelect
                        id={name}
                        label={t('FLUORESENCE_INTENSITY')}
                        options={_DIAMOND_FLUORESCENSE_OPTIONS}
                        onChange={onChange}
                        selectedValue={value}
                        disabledOuterWrap
                    />
                )}
            />
            <HSeparator mb='25px' />
            <Controller
                control={control}
                name="diamondShadeIds"
                defaultValue={[]}
                render={({ onChange, value, name }) => (
                    <CustomOptionSelect
                        id={name}
                        label={t('SHADE')}
                        options={_DIAMOND_TINGE_SHADE_OPTIONS}
                        onChange={onChange}
                        selectedValue={value}
                        disabledOuterWrap
                    />
                )}
            />
            <HSeparator mb='25px' />
            <Controller
                control={control}
                name="diamondMilkyIds"
                defaultValue={[]}
                render={({ onChange, value, name }) => (
                    <CustomOptionSelect
                        id={name}
                        label={t('MILKY')}
                        options={_DIAMOND_TINGE_MILKY_OPTIONS}
                        onChange={onChange}
                        selectedValue={value}
                        disabledOuterWrap
                    />
                )}
            />
            <HSeparator mb='25px' />
            <Controller
                control={control}
                name="diamondEyeCleanIds"
                defaultValue={[]}
                render={({ onChange, value, name }) => (
                    <CustomOptionSelect
                        id={name}
                        label={t('EYE_CLEAN')}
                        options={_DIAMOND_TINGE_EYE_CLEAN_OPTIONS}
                        onChange={onChange}
                        selectedValue={value}
                        disabledOuterWrap
                    />
                )}
            />
            <HSeparator mb='25px' />
            <Flex alignItems={'center'}>
                <Flex flexDirection={'column'} mb={'15px'}>
                    <Text textAlign={'left'} width={'150px'} marginRight={'10px'} fontWeight={'bold'}>{t("TOTAL_PRICE_RANGE")}</Text>
                </Flex>
                <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'center'} className="option-select" flexWrap={'wrap'} gap={5}>
                    <Controller
                        control={control}
                        name="priceMinRange"
                        defaultValue={""}
                        render={({ onChange, value, name }) => (
                            <CustomInput
                                inputType={'decimal'}
                                id={name}
                                onChange={(value) => onChange(value)}
                                value={value}
                                placeHolder={"FROM"}
                                errors={errors}
                            />
                        )}
                        rules={{
                            required: true,
                            message: "REQUIRED",
                            pattern: RegexPattern._TWO_DECIMAL_VALIDATION
                        }}
                    />
                    <Controller
                        control={control}
                        name="priceMaxRange"
                        defaultValue={""}
                        render={({ onChange, value, name }) => (
                            <CustomInput
                                inputType={'decimal'}
                                id={name}
                                onChange={(value) => onChange(value)}
                                value={value}
                                placeHolder={"TO"}
                                errors={errors}
                            />
                        )}
                        rules={{
                            required: true,
                            message: "REQUIRED",
                            pattern: RegexPattern._TWO_DECIMAL_VALIDATION
                        }}
                    />
                </Flex>
            </Flex>
            <HSeparator mb='25px' />
            <Flex justifyContent={'space-between'}>
                <Button
                    fontSize="md"
                    variant="outline"
                    colorScheme="red"
                    fontWeight="500"
                    mb="24px"
                    type="button"
                    margin={'5px'}
                    onClick={() => {
                        onCancel();
                    }}
                >
                    {t('CANCEL')}
                </Button>
                <Button
                    fontSize="md"
                    variant="brand"
                    fontWeight="500"
                    mb="24px"
                    type="submit"
                    margin={'5px'}
                >
                    {t('NEXT')}
                </Button>
            </Flex>
        </form>
    )
}