import React, { useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import {
    Stack,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    FormControl
} from "@chakra-ui/react";
import { disposeCustomInputDialog } from "../application/action/app_action";
import { stringIsNullOrEmpty } from "../common/util";
import CustomInput from "./custom-input";
import CustomSelect from "./custom-select";
import { useForm, Controller } from "react-hook-form";

/// <summary>
/// Author: CK
/// </summary>
const CustomInputDialog = ({
    rowId,
    isVisible,
    title,
    content,
    onConfirm,
    onCancel,
    confirmTxt = 'OK',
    isManualHideAlert = false,
    inputType = 'text', // tel | number, ...
    isPassword = false,
    icon,
    isTextArea = false,
    style,
    isRequired = false,
    options = [],
    defaultValue = "",
    showEmptyOption = false
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef();
    const { control, handleSubmit, errors, setValue, watch, clearErrors } = useForm();
   
    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        if (isVisible) {
            onOpen();
        }
        else {
            closeDialogHandler();
        }
    }, [isVisible]);

    /// <summary>
    /// Author: CK
    /// </summary>
    async function closeDialogHandler() {
        onClose();
        dispatch(disposeCustomInputDialog());
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function cancelHandler() {
        if (onCancel) {
            onCancel();
        }

        closeDialogHandler();
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    const submitForm = async (data, e) => {
        if (onConfirm) {
            // Submit with id and input
            if (rowId) {
                onConfirm({ id: rowId, value: data.dialogInput });

            }
            else {
                onConfirm(data.dialogInput);
            }
        }

        if (!isManualHideAlert) {
            closeDialogHandler();
        }
    };

    return (
        <Modal size={'md'} isOpen={isOpen} onClose={() => cancelHandler()}>
            <ModalOverlay />
            <ModalContent
                containerProps={{
                    zIndex: '999990',
                }}>
                <FormControl>
                    <form onSubmit={handleSubmit(submitForm)}>
                        {!stringIsNullOrEmpty(title) &&
                            <ModalHeader fontSize={'lg'}>
                                {t(title)}
                            </ModalHeader>}
                        <ModalCloseButton />
                        <ModalBody>
                            {
                                inputType == "select" ?
                                    <Controller
                                        control={control}
                                        name="dialogInput"
                                        defaultValue={defaultValue}
                                        render={({ onChange, value, name }) => (
                                            <CustomSelect
                                                id={name}
                                                onChange={(value) => onChange(value)}
                                                label={t(content)}
                                                value={value}
                                                errors={errors}
                                                showEmptyOption={showEmptyOption}
                                                options={options}
                                            />
                                        )}
                                        rules={{
                                            required: isRequired,
                                            message: "REQUIRED",
                                        }}
                                    />
                                    :
                                    <Controller
                                        control={control}
                                        name="dialogInput"
                                        defaultValue={defaultValue}
                                        render={({ onChange, value, name }) => (
                                            <CustomInput
                                                id={name}
                                                onChange={(value) => onChange(value)}
                                                value={value}
                                                placeHolder={t(content)}
                                                errors={errors}
                                                inputType={inputType}
                                                isPassword={isPassword}
                                                icon={icon}
                                                isTextArea={isTextArea}
                                                style={style}
                                            />
                                        )}
                                        rules={{
                                            required: isRequired,
                                            message: "REQUIRED",
                                        }}
                                    />
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Stack direction={'row'} w='100%' spacing={2} justifyContent={'flex-end'}>
                                <Button
                                    type="submit"
                                    size={'sm'}
                                    minW={'60px'}
                                    variant="brand"
                                    borderRadius={5}>
                                    {t(confirmTxt)}
                                </Button>
                            </Stack>
                        </ModalFooter>
                    </form>
                </FormControl>
            </ModalContent>
        </Modal>
    )
}

export default CustomInputDialog;