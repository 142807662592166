import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from "react-redux";
import NonAuthLayout from './layouts/non-auth';
import AdminLayout from './layouts/admin';
import { stringIsNullOrEmpty, isObjectEmpty } from './common/util';
import { ConfigEnum } from './common/config';
import { Language, Role } from './common/constant';
import LoadingScreen from 'components/loading-screen';
import CustomDialog from 'components/custom-dialog';
import CustomInputDialog from 'components/custom-input-dialog';
import InfoCollection from 'views/admin/supplier/info-collection';
import { checkIsLoggedIn } from './application/action/auth_action';

/// <summary>
/// Author: Saitama
/// </summary>
const App = () => {
    const { t, i18n } = useTranslation();
    const _IS_LOGGED_IN = useSelector((state) => state.authState.isLoggedIn);
    const _USER_DATA = useSelector((state) => state.authState.userData);
    var _dispatch = useDispatch();
    var _customDialogData = useSelector((state) => state.appState.customDialogData);
    var _customInputDialogData = useSelector((state) => state.appState.customInputDialogData);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        (async () => {
            if (stringIsNullOrEmpty(localStorage.getItem(ConfigEnum._LANGUAGE))) {
                localStorage.setItem(ConfigEnum._LANGUAGE, Language._ENGLISH);
            }
        })();
    }, []);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (_IS_LOGGED_IN) {
            _dispatch(checkIsLoggedIn());
        }
    }, [_IS_LOGGED_IN]);

    return (
        <div>
            <LoadingScreen />
            <CustomDialog
                isVisible={!isObjectEmpty(_customDialogData)}
                success={_customDialogData.success}
                title={_customDialogData.title}
                content={_customDialogData.content}
                onConfirm={_customDialogData.onConfirm}
                onCancel={_customDialogData.onCancel}
                confirmTxt={_customDialogData.confirmTxt}
                cancelTxt={_customDialogData.cancelTxt}
                disableHardwareBackPress={
                    _customDialogData.disableHardwareBackPress
                }
            />
            <CustomInputDialog
                isVisible={!isObjectEmpty(_customInputDialogData)}
                rowId={_customInputDialogData?.rowId}
                title={_customInputDialogData?.title}
                content={_customInputDialogData?.content}
                onConfirm={_customInputDialogData?.onConfirm}
                onCancel={_customInputDialogData?.onCancel}
                confirmTxt={_customInputDialogData?.confirmTxt}
                cancelTxt={_customInputDialogData?.cancelTxt}
                inputType={_customInputDialogData?.inputType}
                isPassword={_customInputDialogData?.isPassword}
                icon={_customInputDialogData?.icon}
                isTextArea={_customInputDialogData?.isTextArea}
                style={_customInputDialogData?.style}
                isRequired={_customInputDialogData?.isRequired}
                isManualHideAlert={_customInputDialogData?.isManualHideAlert}
                options={_customInputDialogData?.options}
                defaultValue={_customInputDialogData?.defaultValue}
            />
            {
                _IS_LOGGED_IN ?
                    _USER_DATA?.user?.roleId == Role._SUPPLIER && !_USER_DATA?.user?.supplierProfile?.infoCollected ?
                        <InfoCollection /> :
                        <AdminLayout /> :
                    <NonAuthLayout />
            }
        </div>
    )
}

const mapStateToProps = state => ({ ...state });

export default connect(mapStateToProps, null)(App);