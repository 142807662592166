import React, { useEffect, useState } from "react";
import { useRouter } from "../../../common/useRouter";
import { useTranslation } from "react-i18next";
import {
	Box,
	FormLabel,
	Badge,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	IconButton,
	Image,
	Text
} from "@chakra-ui/react";
import { FaGear, FaPen } from "react-icons/fa6";
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../../common/api_routes";
import CustomTable from "../../../components/custom-table";
import { WebUrl } from "../../../routes";
import {
	Role,
	_DIAMOND_SHAPE_OPTIONS,
	_DIAMOND_CLARITY_OPTIONS,
	_DIAMOND_COLOR_OPTIONS,
	_DIAMOND_FINISH_LEVEL_OPTIONS,
	_DIAMOND_FLUORESCENSE_OPTIONS,
	_DIAMOND_LAB_OPTIONS,
	_DIAMOND_TYPE_OPTION,
	CommonStatus,
	SupplierProfileStatus
} from "../../../common/constant";
import { isVerifiedSupplier } from "../../../common/util";
import { showCustomDialog } from "../../../application/action/app_action";
import moment from "moment";

/// <summary>
/// Author: CK
/// </summary>
export default () => {
	var _dispatch = useDispatch();
	var _router = useRouter();
	const { t } = useTranslation();
	const _USER_DATA = useSelector((state) => state.authState.userData);
	const [apiUrl, setApiUrl] = useState();

	const _COLUMNS = [
		..._USER_DATA?.user?.roleId != Role._SUPPLIER ?
			[{
				Header: "SUPPLIER",
				accessor: "supplierName"
			}] : [],
		{
			Header: "REASON",
			accessor: "remark"
		},
		{
			Header: "STOCK_NO",
			accessor: "stockNo"
		},
		{
			Header: "TYPE",
			accessor: "typeId",
			Cell: ({ row }) => {
				return <Text fontWeight={700}>{t(_DIAMOND_TYPE_OPTION.find(i => i.id == row.original.typeId)?.label)}</Text>
			}
		},
		{
			Header: "UPLOADED_DATE_TIME",
			accessor: "createdAt",
			Cell: ({ row }) => {
				return <Text fontWeight={700}>{moment(row.original.createdAt).format('YYYY-MM-DD h:mm:ss A')}</Text>
			}
		},
		{
			Header: "CARAT",
			accessor: "carat"
		},
		{
			Header: "SHAPE",
			accessor: "shapeId",
			Cell: ({ row }) => {
				return <Text fontWeight={700}>{t(_DIAMOND_SHAPE_OPTIONS.find(i => i.id == row.original.shapeId)?.label)}</Text>
			}
		},
		{
			Header: "COLOR",
			accessor: "colorId",
			Cell: ({ row }) => {
				return <Text fontWeight={700}>{t(_DIAMOND_COLOR_OPTIONS.find(i => i.id == row.original.colorId)?.label)}</Text>
			}
		},
		{
			Header: "CLARITY",
			accessor: "clarityId",
			Cell: ({ row }) => {
				return <Text fontWeight={700}>{t(_DIAMOND_CLARITY_OPTIONS.find(i => i.id == row.original.clarityId)?.label)}</Text>
			}
		},
		{
			Header: "CUT",
			accessor: "cutFinishLevelId",
			Cell: ({ row }) => {
				return <Text fontWeight={700}>{t(_DIAMOND_FINISH_LEVEL_OPTIONS.find(i => i.id == row.original.cutFinishLevelId)?.label)}</Text>
			}
		},
		{
			Header: "POLISH",
			accessor: "polishFinishLevelId",
			Cell: ({ row }) => {
				return <Text fontWeight={700}>{t(_DIAMOND_FINISH_LEVEL_OPTIONS.find(i => i.id == row.original.polishFinishLevelId)?.label)}</Text>
			}
		},
		{
			Header: "SYMMETRY",
			accessor: "symmetryFinishLevelId",
			Cell: ({ row }) => {
				return <Text fontWeight={700}>{t(_DIAMOND_FINISH_LEVEL_OPTIONS.find(i => i.id == row.original.symmetryFinishLevelId)?.label)}</Text>
			}
		},
		{
			Header: "FLUO",
			accessor: "fluorescencelId",
			Cell: ({ row }) => {
				return <Text fontWeight={700}>{t(_DIAMOND_FLUORESCENSE_OPTIONS.find(i => i.id == row.original.fluorescencelId)?.label)}</Text>
			}
		},
		{
			Header: "LAB",
			accessor: "labId",
			Cell: ({ row }) => {
				return <Text fontWeight={700}>{t(_DIAMOND_LAB_OPTIONS.find(i => i.id == row.original.labId)?.label)}</Text>
			}
		},
		{
			Header: "CERTIFICATE",
			accessor: "certificateId"
		},
		{
			Header: "PRICE_PER_CARAT",
			accessor: "pricePerCarat"
		},
		{
			Header: "TOTAL",
			Cell: ({ row }) => {
				return <Text fontWeight={700}>{row.original.pricePerCarat * row.original.carat}</Text>
			}
		}
	];

	/// <summary>
	/// Author: CK
	/// Edited: Saitama
	/// </summary> 
	useEffect(() => {
		(async () => {
			let statusCode = await isVerifiedSupplier(_USER_DATA?.user);

			if (statusCode == SupplierProfileStatus._SUCCESS) {
				let currentApiUrl = `${Routes._INVENTORY_DIAMONDS_DRAFT}?status=${CommonStatus._INVALID}`;

				if (_USER_DATA?.user?.roleId == Role._SUPPLIER) {
					currentApiUrl += `&supplierId=${_USER_DATA?.user?.id}`;
				}

				setApiUrl(currentApiUrl);
			}
			else {
				_dispatch(
					showCustomDialog({
						success: false,
						content: statusCode == SupplierProfileStatus._PENDING_APPROVAL ? 'WE_ARE_CURRENTLY_PROCESSING_YOUR_ACCOUNT' : "PLEASE_COMPLETE_YOUR_COMPANY_PROFILE_TO_PROCEED",
						confirmTxt: statusCode == SupplierProfileStatus._PENDING_APPROVAL ? 'OK' : "COMPANY_PROFILE",
						onConfirm: () => {
							if (statusCode == SupplierProfileStatus._EMPTY_PROFILE) {
								_router.navigate(WebUrl._SUPPLIER_PROFILE_SETTINGS);
							}
						}
					})
				);
			}

		})();
	}, []);

	return (
		<Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
			<Card
				justifyContent='center'
				align='center'
				direction='column'
				w='100%'
				mb='0px'>
				<CustomTable
					columnsData={_COLUMNS}
					apiUrl={apiUrl} />
			</Card>
		</Box>
	)
}