import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "../../../common/useRouter";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    IconButton,
    Tooltip,
    Text
} from "@chakra-ui/react";
import { showCustomDialog } from "../../../application/action/app_action";
import Card from "../../../components/card/Card";
import CustomTable from "../../../components/custom-table";
import CustomSwitch from "../../../components/custom-switch";
import { WebUrl } from "../../../routes";
import Routes from "../../../common/api_routes";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../../common/util";
import {
    ApiKey,
    DiamondType,
    _DIAMOND_SHAPE_OPTIONS,
    _DIAMOND_COLOR_OPTIONS,
    _DIAMOND_FANCY_COLOR_OPTIONS,
    _DIAMOND_CLARITY_OPTIONS
} from "../../../common/constant";
import ApiEngine from "../../../common/api-engine";
import { FaPen } from "react-icons/fa6";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const { t } = useTranslation();
    const { control, handleSubmit, errors, setValue, watch } = useForm();
    const [markUpRule, setMarkUpRule] = useState([]);
    const _RULES_TYPE = [
        {
            typeId: DiamondType._NATURAL,
            typeLabel: 'NATURAL',
            isFancyColor: false,
            fancyColorLabel: 'COLOURLESS',
            isActive: false
        },
        {
            typeId: DiamondType._NATURAL,
            typeLabel: 'NATURAL',
            isFancyColor: true,
            fancyColorLabel: 'FANCY_COLOR',
            isActive: false
        },
        {
            typeId: DiamondType._LAB_GROWN,
            typeLabel: 'LAB_GROWN',
            isFancyColor: false,
            fancyColorLabel: 'COLOURLESS',
            isActive: false
        },
        {
            typeId: DiamondType._LAB_GROWN,
            typeLabel: 'LAB_GROWN',
            isFancyColor: true,
            fancyColorLabel: 'FANCY_COLOR',
            isActive: false
        }
    ];
    const _COLUMNS = [
        {
            Header: "DIAMOND_TYPE",
            Cell: ({ row }) => {
                return <Text fontWeight={700}>{`${t(row.original.typeLabel)} ${t(row.original.fancyColorLabel)}`}</Text>
            }
        },
        {
            Header: "DIAMOND_CRITERIA",
            Cell: ({ row }) => {
                let shapeText = "";
                let caratText = `${!stringIsNullOrEmpty(row.original?.caratMinRange) && !stringIsNullOrEmpty(row.original?.caratMaxRange) ? numberWithCurrencyFormat(row.original?.caratMinRange) + ' - ' + numberWithCurrencyFormat(row.original?.caratMaxRange) + " | " : ""
            }`;
                let diamondColor = "";
                let diamondClarity = "";

                _DIAMOND_SHAPE_OPTIONS.forEach(option => {
                    const shapeKey = "shape" + option.label.charAt(0).toUpperCase() + option.label.slice(1).toLowerCase();
                    if (row.original?.diamondShape?.[shapeKey]) {
                        shapeText += t(option.label) + ",";
                    }
                });

                shapeText = !stringIsNullOrEmpty(shapeText) ? ((shapeText.match(/,/g) || []).length == 1 ? shapeText.slice(0, -1) : shapeText.substring(0, 10) + "...") + " | " : ""; 

                if (row.original.isFancyColor) {
                    _DIAMOND_FANCY_COLOR_OPTIONS.forEach(option => {
                        const colorKey = "fancy"+ option.label.charAt(0).toUpperCase() + option.label.slice(1).toLowerCase();
                        if (row.original?.diamondFancyColor?.[colorKey]) {
                            diamondColor += option.label + ",";
                        }
                    });
                }
                else {
                    _DIAMOND_COLOR_OPTIONS.forEach(option => {
                        const colorKey = "color" + option.label;
                        if (row.original?.diamondColor?.[colorKey]) {
                            diamondColor += option.label + ",";
                        }
                    });
                }

                diamondColor = !stringIsNullOrEmpty(diamondColor) ? ((diamondColor.match(/,/g) || []).length == 1 ? diamondColor.slice(0, -1) : diamondColor.substring(0, 10) + "...") + " | " : ""; 

                _DIAMOND_CLARITY_OPTIONS.forEach(option => {
                    const shapeKey = "clarity" + option.label;
                    if (row.original?.diamondClarity?.[shapeKey]) {
                        diamondClarity += t(option.label) + ",";
                    }
                });

                diamondClarity = !stringIsNullOrEmpty(diamondClarity) ? ((diamondClarity.match(/,/g) || []).length == 1 ? diamondClarity.slice(0, -1) : diamondClarity.substring(0, 10) + "..."): ""; 

                return <Text fontWeight={900}>
                    {`${ shapeText }${ caratText }${ diamondColor }${ diamondClarity }`}
                </Text>
            }
        },
        {
            Header: "RULE_STATUS",
            Cell: ({ row }) => {
                return <CustomSwitch
                    disabled={stringIsNullOrEmpty(row.original?.userId)}
                    value={row.original.isActive}
                    onChange={(value) => {
                        updateRuleStatus(row.original.typeId, row.original.isFancyColor, value);
                    }} />
            }
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => {
                return <IconButton icon={<FaPen />} variant={'brand'} onClick={() => {
                    _router.navigate(WebUrl._DIAMOND_FEED_DETAILS, {
                        typeId: row.original.typeId,
                        isFancyColor: row.original.isFancyColor,
                        typeLabel: row.original.typeLabel,
                        fancyColorLabel: row.original.fancyColorLabel
                    })
                }} />
            }
        }
    ];

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        getMarkUpRules();
    }, [])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function getMarkUpRules() {
        try {
            var responseJson = await ApiEngine.get(Routes._INVENTORY_MARKUP_RULES);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            let mergedData = _RULES_TYPE.map(item => {
                return {
                    ...item,
                    ...responseJson[ApiKey._API_DATA_KEY].find(x => x.typeId == item.typeId && x.isFancyColor == item.isFancyColor)
                }
            });

            setMarkUpRule(mergedData);
        }
        catch (err) {
            _dispatch(showCustomDialog({
                success: false,
                content: err,
            }));
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function updateRuleStatus(typeId, isFancyColor, newStatus) {
        try {
            let rulesParams = {
                typeId: typeId,
                isFancyColor: isFancyColor,
                isActive: newStatus
            };

            var responseJson = await ApiEngine.put(Routes._INVENTORY_MARKUP_RULES, rulesParams);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            getMarkUpRules();
        }
        catch (err) {
            _dispatch(showCustomDialog({
                success: false,
                content: err,
            }));
        }
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card
                justifyContent='center'
                align='center'
                direction='column'
                w='100%'
                mb='0px'>
                <CustomTable
                    columnsData={_COLUMNS}
                    tableData={markUpRule} />
            </Card>
        </Box>
    );
}
