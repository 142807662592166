import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Card,
  SimpleGrid,
  useColorModeValue,
  Text,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Link
} from "@chakra-ui/react";
import Usa from "assets/img/dashboards/usa.png";
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
import Tasks from "views/admin/default/components/Tasks";
import TotalSpent from "views/admin/default/components/TotalSpent";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import {
  columnsDataCheck,
  columnsDataComplex,
} from "views/admin/default/variables/columnsData";
import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from 'common/useRouter';
import { WebUrl } from "../../../routes";
import {
  Role,
  CompanyProfileStatus
} from "../../../common/constant";

/// <summary>
/// Author: Saitama
/// </summary>
export default function UserReports() {
  var _router = useRouter();
  const { t } = useTranslation();
  const _USER_DATA = useSelector((state) => state.authState.userData);
  const brandColor = useColorModeValue("brand.800", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  // Chakra Color Mode
  const textColor = useColorModeValue("black", "white");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {
        (_USER_DATA?.user?.roleId == Role._SUPPLIER && _USER_DATA?.user?.companyProfile == undefined) ?
          <Alert status='warning' mb={'15px'}>
            <AlertIcon />
            {t("PLEASE_COMPLETE_COMPANY_PROFILE")} <Link onClick={() => _router.navigate(WebUrl._SUPPLIER_PROFILE_SETTINGS)} ml="5px" color="teal">{t("CLICK_HERE_TO_SUBMIT")}</Link>
          </Alert>
          :
          (_USER_DATA?.user?.roleId == Role._SUPPLIER && _USER_DATA?.user?.companyProfile?.isApproved == CompanyProfileStatus._PENDING_APPROVAL) ?
            <Alert status='warning' mb={'15px'}>
              <AlertIcon />
              {t("COMPANY_PROFILE_PENDING_APPROVAL")}
            </Alert> :
            (_USER_DATA?.user?.roleId == Role._SUPPLIER && _USER_DATA?.user?.companyProfile?.isApproved == CompanyProfileStatus._PENDING_APPROVAL) ?
              <Alert status='error' mb={'15px'}>
                <AlertIcon />
                {t("COMPANY_PROFILE_REJECTED")}
              </Alert> : <></>
      }
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name={t('PENDING_APPROVAL_DIAMONDS')}
          value='0'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={MdAddTask} color='white' />}
            />
          }
          name={t('DIAMONDS')}
          value='0'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />
              }
            />
          }
          name='On Hold Diamonds'
          value='0'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />
              }
            />
          }
          name='Orders'
          value='0'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />
              }
            />
          }
          name='To Ship'
          value='0'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />
              }
            />
          }
          name='Completed'
          value='0'
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <TotalSpent />
        <Card align='center' direction='column' w='100%'>
          <Flex align='center' w='100%' px='15px' py='10px'>
            <Text
              me='auto'
              color={textColor}
              fontSize='xl'
              fontWeight='700'
              lineHeight='100%'>
              {t('SALES_PERSON')}
            </Text>
            <Button
              align='center'
              justifyContent='center'
              bg={bgButton}
              _hover={bgHover}
              _focus={bgFocus}
              _active={bgFocus}
              w='37px'
              h='37px'
              lineHeight='100%'
              borderRadius='10px'
            >
              <Icon as={MdBarChart} color={iconColor} w='24px' h='24px' />
            </Button>
          </Flex>
        </Card>
      </SimpleGrid>
      {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>

        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );
}
