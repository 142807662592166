import React, { useEffect, useState } from "react";
import { useRouter } from "../../../common/useRouter";
import { useTranslation } from "react-i18next";
import {
	Box,
	Badge,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	IconButton,
	Text
} from "@chakra-ui/react";
import { FaGear, FaPen } from "react-icons/fa6";
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../../common/api_routes";
import CustomTable from "../../../components/custom-table";
import { WebUrl } from "../../../routes";
import { Role } from "../../../common/constant";
import { showCustomDialog } from "../../../application/action/app_action";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
	var _dispatch = useDispatch();
	var _router = useRouter();
	const { t } = useTranslation();
	const _USER_DATA = useSelector((state) => state.authState.userData);
	const [apiUrl, setApiUrl] = useState(`${Routes._USER}?roleId=${Role._SALES_PERSON}`);

	const _COLUMNS = [
        {
			Header: "STATUS",
			accessor: "isActive",
            Cell: ({ value }) => {
                return <Badge colorScheme={value ? 'green' : 'red'}>{t(value ? 'ACTIVE' : 'DEACTIVE')}</Badge>
            }
		},
        {
			Header: "TITLE",
			Cell: ({ row }) => {
				return <Text fontWeight={700}>{row.original.salesProfile.title}</Text>
			}
		},
		{
			Header: "USERNAME",
			accessor: "username"
		},
        {
			Header: "NAME",
			Cell: ({ row }) => {
				return <Text fontWeight={700}>{`${row.original.firstName} ${row.original.lastName}`}</Text>
			}
		},
        {
			Header: "EMAIL",
			accessor: "email"
		},
        {
			Header: "TELEPHONE",
			accessor: "phoneNumber"
		},
        {
            Header: "ACTION",
            Cell: ({ row }) => {
                return <Menu>
                    <MenuButton as={IconButton} icon={<FaGear />} variant={'brand'} />
                    <MenuList>
                        <MenuItem icon={<FaPen />} onClick={() => { _router.navigate(WebUrl._CREATE_USER, { salesPersonId: row.original.id }) }}>{t('EDIT')}</MenuItem>
                    </MenuList>
                </Menu>
            }
        }
	];

	/// <summary>
	/// Author:  Saitama
	/// </summary> 
    async function getUserListing() {

    }

	return (
		<Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
			<Card
				justifyContent='center'
				align='center'
				direction='column'
				w='100%'
				mb='0px'>
				<CustomTable
					columnsData={_COLUMNS}
					apiUrl={apiUrl} />
			</Card>
		</Box>
	)
}