import React from "react";
import { Role } from "./common/constant";
import { Icon } from "@chakra-ui/react";
import {
  MdHome,
  MdLock,
  MdWarning,
  MdUpload,
  MdOutlineDiamond,
  MdSearch,
  MdPersonAdd,
  MdGroup,
  MdOutlinePendingActions,
  MdOutlinePriceChange
} from "react-icons/md";
import { RiDraftFill, RiFileUserFill, RiUserFollowFill, RiFileShieldFill, RiShieldUserLine } from "react-icons/ri";
import { FaHandsHoldingCircle, FaFileInvoiceDollar, FaRegFile } from "react-icons/fa6";
import { GiRingBox } from "react-icons/gi";
import MainDashboard from "./views/admin/default";
import SignIn from "./views/non-auth/signIn";
import Register from "./views/non-auth/register";
import ForgotPassword from "./views/non-auth/forgot-password";
import ChangePassword from "./views/non-auth/change-password";
import InventoryUpload from "./views/admin/inventory/upload";
import DiamondsDraft from "./views/admin/inventory/diamonds-draft";
import InvalidDiamonds from "./views/admin/inventory/invalid-diamonds";
import Diamonds from "./views/admin/inventory/diamonds";
import PendingApprovalDiamonds from "./views/admin/inventory/pending-approval-diamonds";
import SearchDiamonds from "./views/admin/inventory/search-diamonds";
import ProfileSettings from "./views/admin/supplier/profile";
import CreateUser from "./views/admin/user/create-user";
import UserList from "./views/admin/user/user-list";
import SupplierApplication from "./views/admin/user/supplier-application";
import SupplierList from "./views/admin/user/supplier-list";
import JewellerApplication from "./views/admin/user/jeweller-application";
import JewellerList from "./views/admin/user/jeweller-list";
import DiamondFeed from "./views/admin/diamond-feed";
import DiamondFeedDetails from "./views/admin/diamond-feed/details";
import SalesPersonSupplier from "./views/admin/user/sales-person-supplier";
import SalesPersonJeweller from "./views/admin/user/sales-person-jeweller";
import OnHoldDiamondsList from "./views/admin/order/on-hold-diamonds-list";
import ConfirmDiamondsList from "./views/admin/order/confirm-diamonds-list";
import PendingPaymentList from "./views/admin/order/pending-payment-list";
import ReadyForInvoiceList from "./views/admin/order/ready-for-invoice-list";

/// <summary>
/// Author: Saitama
/// </summary>
export class WebUrl {
  static _DEFAULT = '/';
  static _REGISTER = '/register';
  static _FORGOT_PASSWORD = '/forgot-password';
  static _CHANGE_PASSWORD = '/change-password';
  static _SUPPLIER_INFO_COLLECTION = '/supplier-info-collection';
  static _INVENTORY_UPLOAD = '/inventory/upload';
  static _INVENTORY_DIAMONDS_DRAFT = '/inventory/diamonds-draft';
  static _INVENTORY_INVALID_DIAMONDS = '/inventory/invalid-diamonds';
  static _INVENTORY_PENDING_APPROVAL_DIAMONDS = '/inventory/pending-approval-diamonds';
  static _INVENTORY_DIAMONDS = '/inventory/diamonds';
  static _SUPPLIER_PROFILE_SETTINGS = '/supplier/profile-settings';
  static _INVENTORY_SEARCH = '/inventory/search';
  static _CREATE_USER = '/user/create';
  static _USER_LIST = '/user/list';
  static _SUPPLIER_APPLICATION_LIST = '/user/supplier-application/list';
  static _SUPPLIER_LIST = '/user/supplier/list';
  static _JEWELLER_APPLICATION_LIST = '/user/jeweller-application/list';
  static _JEWELLER_LIST = '/user/jeweller/list';
  static _DIAMOND_FEED = '/diamond-feed';
  static _DIAMOND_FEED_DETAILS = '/diamond-feed/details';
  static _SALES_PERSON_SUPPLIER_LIST = '/user/my-supplier/list';
  static _SALES_PERSON_JEWELLER_LIST = '/user/my-jeweller/list';
  static _ORDER_ON_HOLD_DIAMOND_LIST = '/order/on-hold-diamonds';
  static _ORDER_CONFIRM_DIAMOND_LIST = '/order/confirm-diamonds';
  static _ORDER_PENDING_PAYMENT_LIST = '/order/pending-payment';
  static _ORDER_READY_FOR_INVOICE_LIST = '/order/ready-for-invoice';
}

/// <summary>
/// Author: Saitama
/// </summary>
export const _NON_AUTH_ROUTES = [
  {
    name: "SIGN_IN",
    path: WebUrl._DEFAULT,
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: <SignIn />,
  },
  {
    name: "REGISTER",
    path: WebUrl._REGISTER,
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: <Register />,
  },
  {
    name: "FORGOT_PASSWORD",
    path: WebUrl._FORGOT_PASSWORD,
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: <ForgotPassword />,
  },
  {
    name: "CHANGE_PASSWORD",
    path: WebUrl._CHANGE_PASSWORD,
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: <ChangePassword />,
  },
]

/// <summary>
/// Author: Saitama
/// Remark: Not declaring role key mean open for all roles | empty array mean hide from all roles
/// </summary>
export const _AUTH_ROUTES = [
  {
    name: "MAIN_DASHBOARD",
    path: WebUrl._DEFAULT,
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: <MainDashboard />,
  },
  {
    name: "MY_SUPPLIER",
    roles: [Role._SALES_PERSON],
    path: WebUrl._SALES_PERSON_SUPPLIER_LIST,
    icon: <Icon as={RiUserFollowFill} width='20px' height='20px' color='inherit' />,
    component: <SalesPersonSupplier />,
  },
  {
    name: "MY_JEWELLER",
    roles: [Role._SALES_PERSON],
    path: WebUrl._SALES_PERSON_JEWELLER_LIST,
    icon: <Icon as={RiShieldUserLine} width='20px' height='20px' color='inherit' />,
    component: <SalesPersonJeweller />,
  },
  {
    name: "COMPANY_SETTING",
    path: WebUrl._SUPPLIER_PROFILE_SETTINGS,
    icon: <Icon as={MdOutlineDiamond} width='20px' height='20px' color='inherit' />,
    component: <ProfileSettings />,
    isHidden: true
  },
  {
    name: "USER_SETTINGS",
    category: 'USER_SETTINGS',
    roles: [Role._SUPERADMIN, Role._COMPANY],
    items: [
      {
        name: "SUPPLIER",
        path: WebUrl._SUPPLIER_LIST,
        icon: <Icon as={RiUserFollowFill} width='20px' height='20px' color='inherit' />,
        component: <SupplierList />,
      },
      {
        name: "SUPPLIER_APPLICATION",
        path: WebUrl._SUPPLIER_APPLICATION_LIST,
        icon: <Icon as={RiFileUserFill} width='20px' height='20px' color='inherit' />,
        component: <SupplierApplication />,
      },
      {
        name: "JEWELLER",
        path: WebUrl._JEWELLER_LIST,
        icon: <Icon as={RiShieldUserLine} width='20px' height='20px' color='inherit' />,
        component: <JewellerList />,
      },
      {
        name: "JEWELLER_APPLICATION",
        path: WebUrl._JEWELLER_APPLICATION_LIST,
        icon: <Icon as={RiFileShieldFill} width='20px' height='20px' color='inherit' />,
        component: <JewellerApplication />,
      },
      {
        name: "SALES_PERSON",
        path: WebUrl._USER_LIST,
        icon: <Icon as={MdGroup} width='20px' height='20px' color='inherit' />,
        component: <UserList />,
      },
      {
        name: "CREATE_SALES_PERSON",
        path: WebUrl._CREATE_USER,
        icon: <Icon as={MdPersonAdd} width='20px' height='20px' color='inherit' />,
        component: <CreateUser />,
      }
    ]
  },
  {
    name: "INVENTORY",
    category: 'INVENTORY',
    items: [
      {
        name: "SEARCH",
        path: WebUrl._INVENTORY_SEARCH,
        icon: <Icon as={MdSearch} width='20px' height='20px' color='inherit' />,
        component: <SearchDiamonds />
      },
      {
        name: "UPLOAD_DIAMONDS",
        roles: [Role._SUPPLIER],
        path: WebUrl._INVENTORY_UPLOAD,
        icon: <Icon as={MdUpload} width='20px' height='20px' color='inherit' />,
        component: <InventoryUpload />,
      },
      {
        name: "DIAMONDS_DRAFT",
        roles: [Role._SUPPLIER],
        path: WebUrl._INVENTORY_DIAMONDS_DRAFT,
        icon: <Icon as={RiDraftFill} width='20px' height='20px' color='inherit' />,
        component: <DiamondsDraft />
      },
      {
        name: "INVALID_DIAMONDS",
        roles: [Role._SUPPLIER],
        path: WebUrl._INVENTORY_INVALID_DIAMONDS,
        icon: <Icon as={MdWarning} width='20px' height='20px' color='inherit' />,
        component: <InvalidDiamonds />
      },
      {
        name: "PENDING_APPROVAL_DIAMONDS",
        roles: [Role._SUPERADMIN, Role._COMPANY],
        path: WebUrl._INVENTORY_PENDING_APPROVAL_DIAMONDS,
        icon: <Icon as={MdOutlinePendingActions} width='20px' height='20px' color='inherit' />,
        component: <PendingApprovalDiamonds />
      },
      {
        name: "DIAMONDS",
        path: WebUrl._INVENTORY_DIAMONDS,
        icon: <Icon as={MdOutlineDiamond} width='20px' height='20px' color='inherit' />,
        component: <Diamonds />
      },
      {
        name: "DIAMOND_FEED",
        path: WebUrl._DIAMOND_FEED,
        roles: [Role._SUPERADMIN, Role._COMPANY],
        icon: <Icon as={MdOutlinePriceChange} width='20px' height='20px' color='inherit' />,
        component: <DiamondFeed />
      },
      {
        name: "DIAMOND_FEED_DETAILS",
        path: WebUrl._DIAMOND_FEED_DETAILS,
        roles: [Role._SUPERADMIN, Role._COMPANY],
        icon: <Icon as={MdOutlinePriceChange} width='20px' height='20px' color='inherit' />,
        component: <DiamondFeedDetails />,
        isHidden: true
      }
    ]
  },
  {
    name: "PURCHASEMENT",
    category: 'PURCHASEMENT',
    items: [
      {
        name: "HOLD_DIAMONDS",
        roles: [Role._SUPERADMIN, Role._COMPANY, Role._SALES_PERSON],
        path: WebUrl._ORDER_ON_HOLD_DIAMOND_LIST,
        icon: <Icon as={FaHandsHoldingCircle} width='20px' height='20px' color='inherit' />,
        component: <OnHoldDiamondsList />,
      },
      {
        name: "CONFIRM_GOODS",
        roles: [Role._SUPERADMIN, Role._COMPANY, Role._SALES_PERSON],
        path: WebUrl._ORDER_CONFIRM_DIAMOND_LIST,
        icon: <Icon as={GiRingBox} width='20px' height='20px' color='inherit' />,
        component: <ConfirmDiamondsList />,
      },
      {
        name: "READY_FOR_INVOICE",
        roles: [Role._SUPERADMIN, Role._COMPANY, Role._SALES_PERSON],
        path: WebUrl._ORDER_READY_FOR_INVOICE_LIST,
        icon: <Icon as={FaRegFile} width='20px' height='20px' color='inherit' />,
        component: <ReadyForInvoiceList />,
      },
      {
        name: "PENDING_PAYMENT",
        roles: [Role._SUPERADMIN, Role._COMPANY, Role._SALES_PERSON],
        path: WebUrl._ORDER_PENDING_PAYMENT_LIST,
        icon: <Icon as={FaFileInvoiceDollar} width='20px' height='20px' color='inherit' />,
        component: <PendingPaymentList />,
      }
    ]
  }
];
