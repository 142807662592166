import React, { useMemo, useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    IconButton,
    Tooltip,
    Select,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Button,
    Grid,
    CardBody,
    Image,
    Stack,
    CardFooter,
    Heading,
    AbsoluteCenter,
    Divider
} from "@chakra-ui/react";
import {
    ArrowRightIcon,
    ArrowLeftIcon,
    ChevronRightIcon,
    ChevronLeftIcon
} from "@chakra-ui/icons";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";
import Card from "components/card/Card";
import { RiExpandUpDownFill, RiArrowUpSFill, RiArrowDownSFill } from "react-icons/ri";
import { stringIsNullOrEmpty } from "common/util";
import { showCustomDialog } from "../application/action/app_action";
import ApiEngine from "../common/api-engine";
import { ApiKey } from "../common/constant";
import { MdGridView, MdOutlineTableRows } from "react-icons/md";

/// <summary>
/// Author: Saitama
/// </summary>
export default function CustomTable({
    title,
    columnsData = [],
    tableData = [],
    apiUrl = '',
    objFilter = {},
    requestMethod = ApiKey._API_GET,
    customFetchAction,
    RowAccordionContent = null,
    RowAccordionContentItem = null,
    GridViewItem = null
}) {
    var _dispatch = useDispatch();
    const { t } = useTranslation();
    const _PAGE_SIZE_OPTION = [10, 20, 50, 100, 500];
    const columns = useMemo(() => columnsData, [columnsData]);
    const [data, setData] = useState(tableData);
    const [pageSize, setPageSize] = useState(_PAGE_SIZE_OPTION[3]);
    const [total, setTotal] = useState(tableData.length);
    const [apiQuery, setApiQuery] = useState("");
    const [gridView, setGridView] = useState(false);
    const tableInstance = useTable(
        {
            columns,
            data,
            manualPagination: !stringIsNullOrEmpty(apiQuery),
            pageCount: Math.ceil(total / pageSize),
            initialState: {
                pageIndex: 0,
                pageSize: pageSize,
            },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        state: { pageIndex }
    } = tableInstance;
    const textColor = useColorModeValue("black", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const brandColor = useColorModeValue("brand.100", "secondaryGray.800");
    const headerTextColor = useColorModeValue("black", "white");

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(apiUrl)) {
            setApiQuery(apiUrl);
        }
    }, [apiUrl]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        fetchData();
    }, [apiQuery, pageSize]);

    /// <summary>
    /// Author : Saitama
    /// </summary>
    useEffect(() => {
        if (tableData.length > 0) {
            setTotal(tableData.length);
            setData(tableData);
            gotoPage(0);
        }
    }, [tableData]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function fetchData(pageNo = 1) {
        try {
            if (!stringIsNullOrEmpty(apiQuery)) {
                var responseJson = null;

                if (requestMethod == ApiKey._API_GET) {
                    responseJson = await ApiEngine.get(`${apiQuery}${apiQuery.indexOf('?') > -1 ? '&' : '?'}pageSize=${pageSize}&current=${pageNo}`);
                }
                else {
                    objFilter["current"] = pageNo;
                    objFilter["pageSize"] = pageSize;
                    responseJson = await ApiEngine.post(apiQuery, objFilter);
                }

                if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                    throw responseJson[ApiKey._API_MESSAGE_KEY];
                }

                if (customFetchAction != null) {
                    customFetchAction(responseJson);
                }

                setTotal(responseJson[ApiKey._API_COUNT_KEY] ?? responseJson[ApiKey._API_DATA_KEY].length);
                setData(responseJson[ApiKey._API_DATA_KEY]);
                gotoPage(pageNo - 1);
            }
            else {
                gotoPage(pageNo - 1);
            }
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    return (
        <Card
            direction='column'
            w='100%'
            px='0px'
            mb='20px'
            overflowX={{ sm: "scroll", lg: "scroll" }}>
            <Flex justify='space-between' mb='20px' align='center'>
                <Text
                    color={textColor}
                    fontSize='12px'
                    fontWeight='700'
                    lineHeight='100%'>
                    {!stringIsNullOrEmpty(title) && t(title)}
                </Text>
                <Flex>
                    {
                        GridViewItem != null &&
                        <Tooltip label={!gridView ? t('GRID_VIEW') : t('LIST_VIEW')}>
                            <Button
                                fontSize="sm"
                                variant="brand"
                                fontWeight="500"
                                type="button"
                                onClick={() => setGridView(!gridView)}
                                mr={'5px'}
                                style={{ padding: '10px' }}
                            >
                                {!gridView ? <MdGridView size={'18px'} /> : <MdOutlineTableRows size={'18px'} />}
                            </Button>
                        </Tooltip>
                    }
                    {
                        data.length > 0 &&
                        <Select
                            fontSize={'12px'}
                            w={32}
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                            }}
                        >
                            {_PAGE_SIZE_OPTION.map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {`${t('SHOW')} ${pageSize}`}
                                </option>
                            ))}
                        </Select>
                    }
                </Flex>
            </Flex>
            {
                gridView && GridViewItem ?
                    page.length == 0 ?
                        <AbsoluteCenter>
                            <Heading color={"brand.700"} fontSize={'26px'}>{t('NO_RECORD')}</Heading>
                        </AbsoluteCenter>
                        :
                        <Grid
                            gap={5}
                            templateColumns="repeat(5, 1fr)"
                            templateRows="repeat(2, 1fr)"
                        >
                            {
                                page.map((row, index) => {
                                    prepareRow(row);

                                    return (
                                        <GridViewItem item={row} />
                                    )
                                })
                            }
                        </Grid>
                    :
                    <>
                        {
                            RowAccordionContent == null ?
                                <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px' className="custom-table">
                                    <Thead>
                                        {headerGroups.map((headerGroup, index) => (
                                            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                                {headerGroup.headers.map((column, index) => (
                                                    <Th
                                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                                        key={index}
                                                        borderColor={borderColor}
                                                        width={column.headerWidth}
                                                        className={column.headerClassName}
                                                        style={{ cursor: 'pointer' }}>
                                                        <Flex
                                                            justify='space-between'
                                                            align='center'
                                                            fontSize={{ sm: "12px", lg: "12px" }}
                                                            color={headerTextColor}>
                                                            {(column.customHeader != null ? column.render("customHeader") : t(column.render("Header")))}
                                                            {!column.disableSortBy && (column.isSorted ? (column.isSortedDesc ? <RiArrowDownSFill /> : <RiArrowUpSFill />) : <RiExpandUpDownFill />)}
                                                        </Flex>
                                                    </Th>
                                                ))}
                                            </Tr>
                                        ))}
                                    </Thead>
                                    <Tbody {...getTableBodyProps()}>
                                        {page.map((row, index) => {
                                            prepareRow(row);

                                            return (
                                                <Tr {...row.getRowProps()} key={index} _hover={{ background: brandColor }}>
                                                    {row.cells.map((cell, index) => {
                                                        return (
                                                            <Td
                                                                {...cell.getCellProps()}
                                                                key={index}
                                                                fontSize={{ sm: "13px" }}
                                                                borderColor='transparent'>
                                                                <Text color={textColor} fontSize='13px' fontWeight='500'>
                                                                    {cell.render('Cell')}
                                                                </Text>
                                                            </Td>
                                                        );
                                                    })}
                                                </Tr>
                                            );
                                        })}
                                    </Tbody>
                                </Table> :
                                <Accordion allowToggle>
                                    <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px' className="custom-table">
                                        <Thead>
                                            {headerGroups.map((headerGroup, index) => (
                                                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                                    <Th
                                                        key={-1}
                                                        borderColor={borderColor}
                                                    >
                                                    </Th>
                                                    {headerGroup.headers.map((column, index) => (
                                                        <Th
                                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                                            key={index}
                                                            borderColor={borderColor}
                                                            width={column.headerWidth}
                                                            className={column.headerClassName}
                                                            style={{ cursor: 'pointer' }}>
                                                            <Flex
                                                                justify='space-between'
                                                                align='center'
                                                                fontSize={{ sm: "12px", lg: "12px" }}
                                                                color={headerTextColor}>
                                                                {(column.customHeader != null ? column.render("customHeader") : t(column.render("Header")))}
                                                                {!column.disableSortBy && (column.isSorted ? (column.isSortedDesc ? <RiArrowDownSFill /> : <RiArrowUpSFill />) : <RiExpandUpDownFill />)}
                                                            </Flex>
                                                        </Th>
                                                    ))}
                                                </Tr>
                                            ))}
                                        </Thead>
                                        <Tbody {...getTableBodyProps()}>
                                            {page.map((row, index) => {
                                                prepareRow(row);

                                                return (
                                                    <AccordionItem as={Fragment}>
                                                        <Tr {...row.getRowProps()} key={index} _hover={{ background: brandColor }}>
                                                            <Td>
                                                                <AccordionButton p={0}>
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                            </Td>
                                                            {row.cells.map((cell, index) => {
                                                                return (
                                                                    <Td
                                                                        {...cell.getCellProps()}
                                                                        key={index}
                                                                        fontSize={{ sm: "13px" }}
                                                                        borderColor='transparent'>
                                                                        <Text color={textColor} fontSize='13px' fontWeight='500'>
                                                                            {cell.render('Cell')}
                                                                        </Text>
                                                                    </Td>
                                                                );
                                                            })}
                                                        </Tr>
                                                        <Tr>
                                                            <Td colSpan={row.cells.length + 1}>
                                                                <AccordionPanel>
                                                                    <RowAccordionContent item={RowAccordionContentItem ? row.original[RowAccordionContentItem] : row.original} />
                                                                </AccordionPanel>
                                                            </Td>
                                                        </Tr>
                                                    </AccordionItem>
                                                );
                                            })}
                                        </Tbody>
                                    </Table>
                                </Accordion>
                        }
                    </>
            }
            {
                data.length > 0 &&
                <Flex justifyContent="space-between" m={4} alignItems="center">
                    <Flex>
                        <Tooltip label={t('FIRST_PAGE')}>
                            <IconButton
                                onClick={() => fetchData()}
                                isDisabled={!canPreviousPage}
                                icon={<ArrowLeftIcon h={3} w={3} />}
                                mr={4}
                            />
                        </Tooltip>
                        <Tooltip label={t('PREVIOUS_PAGE')}>
                            <IconButton
                                onClick={() => fetchData(pageIndex)}
                                isDisabled={!canPreviousPage}
                                icon={<ChevronLeftIcon h={6} w={6} />}
                            />
                        </Tooltip>
                    </Flex>

                    <Flex alignItems="center">
                        <Text fontSize={'12px'} flexShrink="0" mr={8}>
                            {t('PAGE') + " "}
                            <Text fontWeight="bold" as="span">
                                {pageIndex + 1}
                            </Text>{" "}
                            {t('OF') + " "}
                            <Text fontWeight="bold" as="span">
                                {pageOptions.length}
                            </Text>
                        </Text>
                        <Text fontSize={'12px'} flexShrink="0">{t('GO_TO_PAGE')}:</Text>{" "}
                        <NumberInput
                            size={'sm'}
                            ml={2}
                            mr={8}
                            w={28}
                            min={1}
                            max={pageOptions.length}
                            onChange={(value) => {
                                fetchData(value);
                            }}
                            defaultValue={pageIndex + 1}
                        >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </Flex>

                    <Flex>
                        <Tooltip label={t('NEXT_PAGE')}>
                            <IconButton
                                onClick={() => fetchData(pageIndex + 2)}
                                isDisabled={!canNextPage}
                                icon={<ChevronRightIcon h={6} w={6} />}
                            />
                        </Tooltip>
                        <Tooltip label={t('LAST_PAGE')}>
                            <IconButton
                                onClick={() => fetchData(pageCount)}
                                isDisabled={!canNextPage}
                                icon={<ArrowRightIcon h={3} w={3} />}
                                ml={4}
                            />
                        </Tooltip>
                    </Flex>
                </Flex>
            }
        </Card >
    );
}
